import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  listOrderDetails,
  updateOrderPayInPerson,
} from "../Redux/Actions/OrderActions";
import Loading from "../Components/Common/Loading";
import moment from "moment";

const CheckoutInPersonStatusScreen = () => {
  const { id } = useParams();
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [date, setDate] = useState("");
  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState("");
  const [total, setTotal] = useState("");

  const location = useLocation();

  const dispatch = useDispatch();
  const orderDetailState = useSelector((state) => state.orderDetails);
  const { loading, error, order } = orderDetailState;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Extract the parameter values from the URL

    const customerAddressParam = searchParams.get("customer_address");
    const customerEmailParam = searchParams.get("customer_email");
    const customerNameParam = searchParams.get("customer_name");
    const dateParam = moment(Date.now()).calendar();
    const orderIdParam = searchParams.get("order_id");
    const statusParam = searchParams.get("status");
    const totalParam = searchParams.get("totalCost");

    // Set the state with the parameter values

    setCustomerAddress(customerAddressParam || "");
    setCustomerEmail(customerEmailParam || "");
    setCustomerName(customerNameParam || "");
    setDate(dateParam || "");
    setOrderId(orderIdParam || "");
    setStatus(statusParam || "");
    setTotal(totalParam || "");
    console.log(searchParams);
    // Rest of your component code...
  }, [location]);

  useEffect(() => {
    console.log(order);

    if (!order) {
      dispatch(listOrderDetails(id));
    } else if (order) {
      if (order._id != id) {
        dispatch(listOrderDetails(id));
      }
      if (order.isPaid != undefined && order.isPaid == false) {
        dispatch(
          updateOrderPayInPerson({
            _id: id,
            customerName,
            customerEmail,
            date,
            total,
            isPaid: true,
          })
        );
      }
    }
  }, [order]);

  return (
    <React.Fragment>
      <Navbar />
      <div className="ci-tracking-screen-container ci-first-section ci-container">
        <div className="ci-tracking-screen-heading">
          {loading ? (
            <Loading color="orange" w="60px" h="60px" />
          ) : error ? (
            <React.Fragment>
              <h3>Order not Found</h3>
              <Link className="admin-header-backlink" to="/">
                Click to Back
              </Link>
            </React.Fragment>
          ) : (
            order &&
            status && (
              <React.Fragment>
                <h2>
                  {order.customer.fname.toUpperCase()}{" "}
                  {order.customer.lname.toUpperCase()} - ORDER{" "}
                  {status.toUpperCase()}
                </h2>
                <div className="mb-4 row">
                  <div className="col-12 admin-order-details-wrap">
                    <h4>Order Details</h4>
                    <div className="admin-order-details-table-items">
                      <div className="admin-order-details-row">
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Name</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Product Link</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Quantity</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Price</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Total</b>
                          </p>
                        </div>
                      </div>
                      {order.orderItems &&
                        order.orderItems.map((item, i) => (
                          <div key={i} className="admin-order-details-row">
                            <div className="admin-order-details-cell">
                              <p>{item.name}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <Link to={`/product/${item.product}`}>
                                View Item
                              </Link>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>{item.qty}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>{item.price}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>{item.price * item.qty}</p>
                            </div>
                          </div>
                        ))}
                      <div className="admin-order-details-row">
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Total (+$45 delivery)</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>{order.totalPrice}</b>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h4 className="ci-tracking-status">
                        Payment:{" "}
                        <span className="ci-tracking-time">
                          Will be Paid In Person
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 admin-order-details-wrap">
                  <h3 className="ci-payment-header">Payment Information</h3>
                  <div className="admin-order-details-row">
                    <div className="admin-order-details-h-cell">
                      <p>Order ID:</p>
                    </div>
                    <div className="admin-order-details-cell">
                      <p>{orderId}</p>
                    </div>
                  </div>

                  <div className="admin-order-details-row">
                    <div className="admin-order-details-h-cell">
                      <p>Customer Address:</p>
                    </div>
                    <div className="admin-order-details-cell">
                      <p>{customerAddress}</p>
                    </div>
                  </div>
                  <div className="admin-order-details-row">
                    <div className="admin-order-details-h-cell">
                      <p>Customer Email:</p>
                    </div>
                    <div className="admin-order-details-cell">
                      <p>{customerEmail}</p>
                    </div>
                  </div>
                  <div className="admin-order-details-row">
                    <div className="admin-order-details-h-cell">
                      <p>Customer Name:</p>
                    </div>
                    <div className="admin-order-details-cell">
                      <p>{customerName}</p>
                    </div>
                  </div>
                  <div className="admin-order-details-row">
                    <div className="admin-order-details-h-cell">
                      <p>Date Purchased:</p>
                    </div>
                    <div className="admin-order-details-cell">
                      <p>{date}</p>
                    </div>
                  </div>
                  <div className="admin-order-details-row">
                    <div className="admin-order-details-h-cell">
                      <p>Status:</p>
                    </div>
                    <div className="admin-order-details-cell">
                      <p>{status}</p>
                    </div>
                  </div>
                  <div className="admin-order-details-row">
                    <div className="admin-order-details-h-cell">
                      <p>Total:</p>
                    </div>
                    <div className="admin-order-details-cell">
                      <p>{total}</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          )}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default CheckoutInPersonStatusScreen;
