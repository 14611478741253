import axios from "axios";
import {
  CONTROL_LIST_FAIL,
  CONTROL_LIST_REQUEST,
  CONTROL_LIST_SUCCESS,
  CONTROL_UPDATE_FAIL,
  CONTROL_UPDATE_REQUEST,
  CONTROL_UPDATE_SUCCESS,
} from "../Constants/ControlConstants";
import url from "../../URL";
import { toast } from "react-toastify";
import { logout } from "./UserActions";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

// GET ALL CONTROLS
export const listControls = () => async (dispatch) => {
  try {
    dispatch({ type: CONTROL_LIST_REQUEST });
    const { data } = await axios.get(`${url}/api/controls`);
    dispatch({
      type: CONTROL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTROL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//UPDATE CONTROLS
export const updateControl = (control) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTROL_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${url}/api/controls/${control._id}`,
      control,
      config
    );

    dispatch({ type: CONTROL_UPDATE_SUCCESS, payload: data });
    dispatch({ type: CONTROL_LIST_SUCCESS, payload: data });
    toast.success("Controls Updated Successfully", ToastObjects);
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }

    dispatch({
      type: CONTROL_UPDATE_FAIL,
      payload: message,
    });
    toast.error(message, ToastObjects);
  }
};
