import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { login } from "../../Redux/Actions/UserActions";
import Toast from "../../Components/Toast";
import AdminHeader from "../../Components/admin/AdminHeader";
import Loading from "../../Components/Common/Loading";
import ErrorMessage from "../../Components/Common/Error";

const AdminLoginScreen = () => {
  window.scrollTo(0, 0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/admin/");
    }
  }, [userInfo, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };
  return (
    <div className="ci-login-screen">
      <AdminHeader />
      <Toast />
      <div className="login-container">
        <div
          className="card shadow mx-auto ci-card"
          style={{ maxWidth: "380px" }}
        >
          <div className="card-body ">
            <h3 className="card-title mb-4 text-center">
              Berly Essentials Admin Portal
            </h3>
            <p className="text-center">Enter your login information</p>
            {loading && <Loading color="orange" w="60px" h="60px" />}
            {error && <ErrorMessage message={error} />}
            <form onSubmit={submitHandler}>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-4 text-center">
                <button type="submit" className="ci-solid-btn-blue">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginScreen;
