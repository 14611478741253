import {
  CONTROL_LIST_FAIL,
  CONTROL_LIST_REQUEST,
  CONTROL_LIST_SUCCESS,
  CONTROL_UPDATE_FAIL,
  CONTROL_UPDATE_REQUEST,
  CONTROL_UPDATE_RESET,
  CONTROL_UPDATE_SUCCESS,
} from "../Constants/ControlConstants";

export const controlListReducer = (state = { controls: [{}] }, action) => {
  switch (action.type) {
    case CONTROL_LIST_REQUEST:
      return { loading: true, controls: [] };
    case CONTROL_LIST_SUCCESS:
      return { loading: false, controls: action.payload };
    case CONTROL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//UPDATE CONTROLS
export const controlUpdateReducer = (
  state = { updatedcontrol: {} },
  action
) => {
  switch (action.type) {
    case CONTROL_UPDATE_REQUEST:
      return { ...state, loading: true };
    case CONTROL_UPDATE_SUCCESS:
      return { loading: false, success: true, updatedcontrol: action.payload };
    case CONTROL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CONTROL_UPDATE_RESET:
      return { updatedcontrol: {} };
    default:
      return state;
  }
};
