import React, { useEffect, useState } from "react";
import Toast from "../../Components/Toast";
import AdminHeader from "../../Components/admin/AdminHeader";
import AdminSidebar from "../../Components/admin/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import { createProduct } from "../../Redux/Actions/ProductActions";
import UploadWidget from "../../Components/admin/UploadWidget";
import { PRODUCT_CREATE_RESET } from "../../Redux/Constants/ProductConstants";

const AdminAddProductScreen = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [subimages, setSubImages] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0.0);
  const [countInStock, setCountInStock] = useState(0);
  const [promotext, setPromotext] = useState("");
  const [type, setType] = useState("");
  const [oldprice, setOldPrice] = useState(0.0);

  const dispatch = useDispatch();
  const productCreateState = useSelector((state) => state.productCreate);
  const { loading, error, product, success } = productCreateState;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });
    if (product) {
      setName("");
      setImage("");
      setSubImages([]);
      setDescription("");
      setPrice(0.0);
      setCountInStock(0);
      setPromotext("");
      setType("");
      setOldPrice(0.0);
    }
  }, [product, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProduct(
        name,
        image,
        subimages,
        description,
        price,
        countInStock,
        promotext,
        type,
        oldprice
      )
    );
  };
  const handleCurrency = (e) => {
    let inputValue = e.target.value;
    const formattedValue = `${Number(inputValue).toLocaleString("en")}.00`;
    return formattedValue;
  };
  return (
    <div>
      <AdminHeader />
      <Toast />
      <div className="interface-container">
        <AdminSidebar />
        <div className="main-display">
          <div className="admin-main-container">
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : error ? (
              <React.Fragment>
                <h3>Unsuccessful: {error}</h3>
                <Link className="admin-header-backlink" to="/admin/products/">
                  Go to Products Screen
                </Link>
              </React.Fragment>
            ) : success ? (
              <React.Fragment>
                <h3>Product added successfully</h3>
                <Link className="admin-header-backlink" to="/admin/products/">
                  Go to Products Screen
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="mb-5 admin-top-initial-container">
                  <h2 className="admin-header admin-inline">Add New Item</h2>
                  <Link
                    style={{
                      textDecoration: "none !important",
                      color: "var(--lavender-dark)",
                    }}
                    className="admin-header-backlink"
                    to="/admin/products"
                  >
                    <i
                      style={{ color: "var(--orange)" }}
                      className="fas fa-arrow-left"
                    ></i>{" "}
                    Go to Products
                  </Link>
                </div>
                <form onSubmit={submitHandler}>
                  <h3 className="admin-form-heading mb-3">
                    Product Information{" "}
                    <span
                      style={{
                        paddingLeft: "10px",
                        fontSize: "small",
                        color: "hotpink",
                        fontWeight: "normal",
                      }}
                    >
                      * means field is required
                    </span>
                  </h3>
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_name" className="form-label">
                        Product Name <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Name of Product"
                        className="form-control"
                        id="product_name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_price" className="form-label">
                        Product Price{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Price of Product"
                        className="form-control"
                        id="product_price"
                        required
                        value={price}
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                        onBlur={(e) => setPrice(handleCurrency(e))}
                      />
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_oldprice" className="form-label">
                        Previous Cost
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "var(--lavender)",
                            fontSize: "small",
                            paddingLeft: "10px",
                          }}
                        >
                          (Shown like this)
                        </span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter OldPrice for Product"
                        className="form-control"
                        id="product_oldprice"
                        value={oldprice}
                        onChange={(e) => {
                          setOldPrice(e.target.value);
                        }}
                        onBlur={(e) => setOldPrice(handleCurrency(e))}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_type" className="form-label">
                        Product Type <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Type of Product"
                        className="form-control"
                        id="product_type"
                        required
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_promotext" className="form-label">
                        Promotion{" "}
                        <span
                          style={{
                            color: "var(--lavender)",
                            fontSize: "small",
                            paddingLeft: "10px",
                          }}
                        >
                          (For % off sales)
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Promotional Text for Product"
                        className="form-control"
                        id="product_promotext"
                        maxLength={6}
                        value={promotext}
                        onChange={(e) => {
                          setPromotext(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label
                        htmlFor="product_countInStock"
                        className="form-label"
                      >
                        Initial Count in Stock{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter initial amount in stock."
                        className="form-control"
                        id="product_countInStock"
                        required
                        value={countInStock}
                        onChange={(e) => {
                          setCountInStock(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-lg-12 p-2">
                      <label
                        htmlFor="product_description"
                        className="form-label"
                      >
                        Description <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <textarea
                        id="product_description"
                        placeholder="Enter Description"
                        className="form-control"
                        rows="6"
                        required
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  {/* Images */}
                  <h3 className="admin-form-heading mb-3">Product Images</h3>
                  <div className="mb-4 row align-products-center">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_mainImage" className="form-label">
                        Main Image <span style={{ color: "hotpink" }}>*</span>
                      </label>

                      <UploadWidget
                        onUpload={(url) => {
                          setImage(url);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      {image && (
                        <img
                          src={image}
                          className="adminImagePreview"
                          alt="main_image"
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-4 row align-products-center">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_mainImage" className="form-label">
                        Sub Image 1
                      </label>

                      <UploadWidget
                        onUpload={(url) => {
                          if (subimages.length !== 0) {
                            setSubImages([...subimages, url]);
                          } else {
                            setSubImages([url]);
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      {subimages && subimages[0] && (
                        <img
                          src={subimages[0]}
                          className="adminImagePreview"
                          alt="sub_image_0"
                        />
                      )}
                    </div>
                  </div>
                  {subimages[0] && (
                    <div className="mb-4 row align-products-center">
                      <div className="col-lg-6 col-sm-12 p-2">
                        <label
                          htmlFor="product_mainImage"
                          className="form-label"
                        >
                          Sub Image 2
                        </label>

                        <UploadWidget
                          onUpload={(url) => {
                            setSubImages([...subimages, url]);
                          }}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12 p-2">
                        {subimages && subimages[1] && (
                          <img
                            src={subimages[1]}
                            className="adminImagePreview"
                            alt="sub_image_0"
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {subimages[1] && (
                    <div className="mb-4 row align-products-center">
                      <div className="col-lg-6 col-sm-12 p-2">
                        <label
                          htmlFor="product_mainImage"
                          className="form-label"
                        >
                          Sub Image 3
                        </label>

                        <UploadWidget
                          onUpload={(url) => {
                            setSubImages([...subimages, url]);
                          }}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12 p-2">
                        {subimages && subimages[2] && (
                          <img
                            src={subimages[2]}
                            className="adminImagePreview"
                            alt="sub_image_0"
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="w-100 center admin-prodbtn-container">
                    <button
                      type="submit"
                      className="ci-btn-full-light-blue admin-add-button"
                    >
                      Add product
                    </button>
                  </div>
                </form>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddProductScreen;
