import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productCreateReducer,
  productCreateReviewReducer,
  productDeleteReducer,
  productDeleteReviewReducer,
  productDetailsReducer,
  productListReducer,
  productUpdateReducer,
  productUpdateStockReducer,
} from "./Reducers/ProductReducers";
import {
  controlListReducer,
  controlUpdateReducer,
} from "./Reducers/ControlReducers";
import {
  orderCreateReducer,
  orderDeleteReducer,
  orderDetailsReducer,
  orderListReducer,
  orderPayUpdateReducer,
  orderUpdateReducer,
  orderUserListReducer,
} from "./Reducers/OrderReducers";
import { cartReducer } from "./Reducers/CartReducers";
import { userIdReducer, userLoginReducer } from "./Reducers/UserReducers";

const reducer = combineReducers({
  controlList: controlListReducer,
  controlUpdate: controlUpdateReducer,
  productList: productListReducer,
  productReviewCreate: productCreateReviewReducer,
  productReviewDelete: productDeleteReviewReducer,
  productCreate: productCreateReducer,
  productDetails: productDetailsReducer,
  productUpdate: productUpdateReducer,
  productUpdateStock: productUpdateStockReducer,
  productDelete: productDeleteReducer,
  orderCreate: orderCreateReducer,
  orderList: orderListReducer,
  orderUserList: orderUserListReducer,
  orderDetails: orderDetailsReducer,
  orderUpdate: orderUpdateReducer,
  orderDelete: orderDeleteReducer,
  orderPay: orderPayUpdateReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userIdInfo: userIdReducer,
});

const cartItemsFromLocalStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const userIdFromLocalStorage = localStorage.getItem("userId")
  ? JSON.parse(localStorage.getItem("userId"))
  : null;

const initialState = {
  cart: {
    cartItems: cartItemsFromLocalStorage,
  },
  userLogin: {
    userInfo: userInfoFromLocalStorage,
  },
  userIdInfo: {
    userId: userIdFromLocalStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
