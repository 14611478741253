export const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  swipeToSlide: false,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 600,
  pauseOnHover: false,
  centerMode: false,
};

export const productSettings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipe: false,
  swipeToSlide: false,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 888,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: true,
        swipeToSlide: true,
        centerMode: true,
        arrows: true,
      },
    },
    {
      breakpoint: 665,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        swipeToSlide: true,
        centerMode: false,
        arrows: true,
      },
    },
  ],
};
