import React, { useEffect, useState } from "react";
import Product from "./../Common/Product";
import Slider from "react-slick";
import { productSettings } from "../../Settings/ReactSlick";
// import { products } from "../../Data/products";
import { useNavigate } from "react-router";
import { Fade, Slide, Bounce } from "react-awesome-reveal";
import Loading from "../Common/Loading";
import { useSelector } from "react-redux";
import ErrorMessage from "../Common/Error";

const Trending = () => {
  const [productTrending, setProductTrending] = useState([]);

  const navigate = useNavigate();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    if (
      products &&
      JSON.stringify(products) !== "[]" &&
      JSON.stringify(products) !== "[{}]"
    ) {
      const newProducts = [...products];
      const sortedNewProducts = newProducts
        .sort((a, b) => (a.purchases > b.purchases ? -1 : 1))
        .slice(0, 10);
      console.log(sortedNewProducts);
      setProductTrending([...sortedNewProducts]);
    }
  }, [products]);

  return (
    <div className="ci-trending-container ci-container ci-section">
      <div className="ci-trending-heading">
        <Slide duration={500}>
          <h2>What's Trending?</h2>
        </Slide>
      </div>

      <div className="ci-trending-gallery">
        {loading ? (
          <Loading color="dark-orange" w="60px" h="60px" />
        ) : error ? (
          <ErrorMessage message={error} />
        ) : (
          <Slider
            {...productSettings}
            infinite={
              10 > productSettings.slidesToShow &&
              productTrending.length > productSettings.slidesToShow
            }
          >
            {productTrending.map((product, i) => (
              <Product
                key={i}
                id={product._id}
                name={product.name}
                price={product.price}
                buttonlabel="Add to Cart"
                promotext={product.promotext}
                promocolor="ci-promo-orange"
                img={product.image}
                count={product.countInStock}
              />
            ))}
          </Slider>
        )}
      </div>

      <div className="ci-trending-button-container">
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate("/products/");
          }}
          className="ci-solid-btn-orange ci-btn-small"
        >
          View All
        </button>
      </div>
    </div>
  );
};

export default Trending;
