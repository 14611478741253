import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Navbar = ({ bannerRef }) => {
  const [search, setSearch] = useState(false);
  const [filter, setFilter] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const navigate = useNavigate();
  const mobileMenuOpenRef = useRef(mobileMenuOpen);
  const cartItemStore = useSelector((state) => state.cart);
  const { cartItems: cart } = cartItemStore;

  //empty bannerRef if it doesnt exist
  !bannerRef ? (bannerRef = {}) : (bannerRef = bannerRef);

  useEffect(() => {
    mobileMenuOpenRef.current = mobileMenuOpen;
  }, [mobileMenuOpen]);

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearch(!search);
  };

  const handleCloseMenu = (e) => {
    if (
      !e.target.closest(".ci-nav-items-mobile-menu") &&
      mobileMenuOpenRef.current
    ) {
      setMobileMenuOpen(false);
    }
  };

  const handleClickMenu = (e) => {
    setMobileMenuOpen(!mobileMenuOpen);
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseMenu);

    return () => {
      document.removeEventListener("click", handleCloseMenu);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBannerVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);
  return (
    <div
      className={`ci-nav-container ci-container ${
        isBannerVisible ? "" : "ci-not-over-banner"
      }`}
    >
      <div className="row ci-nav-logo-container">
        <div className="col-3 ci-nav-icon">
          <div className="ci-nav-search-container ci-nav-icon-container mobile-hidden">
            <div>
              <div
                className="ci-search-clicker mobile-hidden"
                onClick={handleSearchClick}
              ></div>
              <i className="fa fa-search"></i>
            </div>
            <input
              type="text"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              placeholder="search here"
              className={`ci-nav-search-input ${
                search ? "nav-click-show" : ""
              }`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(`/products/${filter}`);
                }
              }}
            />
            <Link
              to={`/products/${filter}`}
              className={`ci-nav-search-go ${search ? "nav-click-go" : ""}`}
            >
              Go
            </Link>
          </div>
          <Link
            to="#"
            className={`ci-nav-search-container-mobile ci-nav-icon-container mobile-show ${
              mobileMenuOpen ? "mobile-menu-clicked" : ""
            }`}
            onClick={handleClickMenu}
          >
            <i className="fa fa-bars"></i>
          </Link>
        </div>
        <div className="col-6 ci-nav-logo">
          <img
            onClick={(e) => {
              navigate("/");
            }}
            className={`ci-nav-logo-svg ${isBannerVisible ? "" : ""} `}
            src="/images/svgs/Logo.svg"
            alt=""
          />
        </div>
        <div className="col-3 ci-nav-icon ci-nav-icon-r">
          <Link to="/cart" className="ci-nav-icon-container">
            <span className="ci-nav-icon-text mobile-hidden">Cart</span>
            <i className="fas fa-shopping-cart ci-nav-cart-icon"></i>
            <span className="ci-nav-cart-notification">
              {cart.length > 0 ? <p> {Number(cart.length)}</p> : ""}
            </span>
          </Link>
        </div>
      </div>
      <div className="ci-nav-items-container mobile-hidden">
        <nav className="ci-nav-items">
          <ul>
            <li className="ci-nav-item">
              <NavLink className="ci-nav-item-link" to="/">
                Home
              </NavLink>
            </li>
            <li className="ci-nav-item">
              <NavLink className="ci-nav-item-link" to="/products">
                Products
              </NavLink>
            </li>
            <li className="ci-nav-item">
              <NavLink className="ci-nav-item-link" to="/media">
                Media
              </NavLink>
            </li>
            <li className="ci-nav-item">
              <NavLink className="ci-nav-item-link" to="/tracking">
                Track Orders
              </NavLink>
            </li>
            <li className="ci-nav-item">
              <NavLink className="ci-nav-item-link" to="/contact">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={`${
          mobileMenuOpen ? "mobile-menu-open" : "mobile-menu-close"
        } ci-nav-items-mobile-menu mobile-show`}
      >
        <nav className="ci-nav-mobile-menu-items">
          <ul>
            <li className="ci-nav-mobile-menu-item">
              <NavLink className="ci-nav-mobile-menu-item-link" to="/">
                Home
              </NavLink>
            </li>
            <li className="ci-nav-mobile-menu-item">
              <NavLink className="ci-nav-mobile-menu-item-link" to="/products">
                Products
              </NavLink>
            </li>
            <li className="ci-nav-mobile-menu-item">
              <NavLink className="ci-nav-mobile-menu-item-link" to="/media">
                Media
              </NavLink>
            </li>
            <li className="ci-nav-mobile-menu-item">
              <NavLink className="ci-nav-mobile-menu-item-link" to="/tracking">
                Track Orders
              </NavLink>
            </li>
            <li className="ci-nav-mobile-menu-item">
              <NavLink className="ci-nav-mobile-menu-item-link" to="/contact">
                Contact Us
              </NavLink>
            </li>
            <li className="ci-nav-mobile-menu-item">
              <input
                type="text"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                placeholder="search here"
                className="ci-nav-search-mobile"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/products/${filter}`);
                  }
                }}
              />
              <span>
                <Link to={`/products/${filter}`}>
                  <div
                    className={` ${
                      mobileMenuOpen ? "mobile-search-show" : ""
                    } ci-nav-mobile-search-flex`}
                  >
                    <i className="fa fa-search ci-nav-search-button-mobile"></i>
                  </div>
                </Link>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
