import React, { useEffect, useState } from "react";
import Toast from "../../Components/Toast";
import AdminHeader from "../../Components/admin/AdminHeader";
import AdminSidebar from "../../Components/admin/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  listControls,
  updateControl,
} from "../../Redux/Actions/ControlActions";
import { Link } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import UploadWidget from "../../Components/admin/UploadWidget";
import { CONTROL_UPDATE_RESET } from "../../Redux/Constants/ControlConstants";

const AdminMediaScreen = () => {
  const [hs, setHS] = useState({});
  const [ops, setOPS] = useState({});
  const [ms, setMS] = useState({});
  const [cs, setCS] = useState({});
  const [ps, setPS] = useState({});
  const [control, setControl] = useState({});

  const dispatch = useDispatch();

  const controlDetailState = useSelector((state) => state.controlList);
  const { loading, error, controls } = controlDetailState;

  const controlUpdate = useSelector((state) => state.controlUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = controlUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CONTROL_UPDATE_RESET });
      dispatch(listControls());
    } else {
      if (JSON.stringify(controls) === "[{}]") {
        dispatch(listControls());
      }
      if (
        JSON.stringify(controls) !== "[]" &&
        JSON.stringify(controls) !== "[{}]"
      ) {
        const temp = { ...controls[0] };
        setControl({ ...temp });
      }
    }
  }, [setControl, controls, dispatch, successUpdate]);

  useEffect(() => {
    if (JSON.stringify(control) !== "{}") {
      setHS({ ...control.homeSettings });
      setOPS({ ...control.ourProductSettings });
      setMS({ ...control.mediaSettings });
      setCS({ ...control.contactSettings });
      setPS({ ...control.paymentSettings });
    }
  }, [control]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateControl({
        _id: controls[0]._id,
        homeSettings: hs,
        ourProductSettings: ops,
        mediaSettings: ms,
        contactSettings: cs,
        paymentSettings: ps,
      })
    );
  };

  return (
    <div>
      <AdminHeader />
      <Toast />
      <div className="interface-container">
        <AdminSidebar />
        <div className="main-display">
          <div className="admin-main-container">
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : loadingUpdate ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : errorUpdate ? (
              <React.Fragment>
                <h3>{errorUpdate}</h3>
                <Link className="admin-header-backlink" to="/admin/">
                  Go to Home Screen
                </Link>
              </React.Fragment>
            ) : error ? (
              <React.Fragment>
                <h3>{error}</h3>
                <Link className="admin-header-backlink" to="/admin/">
                  Go to Home Screen
                </Link>
              </React.Fragment>
            ) : successUpdate ? (
              <React.Fragment>
                <h3>Product edited successfully</h3>
                <Link className="admin-header-backlink" to="/admin/">
                  Go to Home Screen
                </Link>
              </React.Fragment>
            ) : JSON.stringify(control) !== "{}" ? (
              <React.Fragment>
                <div className="mb-5 admin-top-initial-container">
                  <h2 className="admin-header admin-inline">Site Settings</h2>
                  <Link
                    style={{
                      textDecoration: "none !important",
                      color: "var(--lavender-dark)",
                    }}
                    className="admin-header-backlink"
                    to="/admin/"
                  >
                    <i
                      style={{ color: "var(--orange)" }}
                      className="fas fa-arrow-left"
                    ></i>{" "}
                    Go to Home
                  </Link>
                </div>
                <form onSubmit={submitHandler}>
                  <h3 className="admin-form-heading mb-3">Images</h3>
                  <div className="control-images-container">
                    {ms.photos &&
                      ms.photos.map((img, i) => (
                        <div key={i} className="control-image-container">
                          <img
                            className="control-image-element"
                            src={img}
                            alt={i}
                          />
                          <i
                            onClick={(e) => {
                              let mstemp = {
                                ...ms,
                                photos: [
                                  ...ms.photos.filter(
                                    (imgtemp) => imgtemp !== img
                                  ),
                                ],
                              };
                              setMS(mstemp);
                            }}
                            className="fa fa-times-circle control-image-remove"
                          ></i>
                        </div>
                      ))}
                  </div>
                  {ms.photos && (
                    <div className="mb-4 row align-products-center">
                      <div className="col-lg-6 col-sm-12 p-2">
                        <label
                          htmlFor="product_mainImage"
                          className="form-label"
                        >
                          Upload Image{" "}
                          <span style={{ color: "hotpink" }}>*</span>
                        </label>

                        <UploadWidget
                          onUpload={(url) => {
                            const mstemp = {
                              ...ms,
                              photos: [...ms.photos, url],
                            };
                            setMS(mstemp);
                          }}
                          val={""}
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-100 center admin-prodbtn-container">
                    <button
                      type="submit"
                      className="ci-btn-full-light-blue admin-add-button"
                    >
                      Save Images
                    </button>
                  </div>
                </form>
                <form onSubmit={submitHandler}>
                  <h3 className="admin-form-heading mb-3 control-videos-heading">
                    Videos
                  </h3>
                  <div className="control-vids-container">
                    {ms.videos &&
                      ms.videos.map((vid, i) => (
                        <div key={i} className="control-vid-container">
                          <span className="control-vid-text">{vid}</span>
                          <i
                            onClick={(e) => {
                              let mstemp = {
                                ...ms,
                                videos: [
                                  ...ms.videos.filter(
                                    (vidtemp) => vidtemp !== vid
                                  ),
                                ],
                              };
                              setMS(mstemp);
                            }}
                            className="fa fa-times-circle control-image-remove"
                          ></i>
                        </div>
                      ))}
                  </div>
                  {ms.videos && (
                    <div className="mb-4 row">
                      <div className="col-lg-6 col-sm-12 p-2">
                        <label htmlFor="ms_video" className="form-label">
                          Add YouTube Video{" "}
                          <span style={{ color: "hotpink" }}>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter YouTube video URL"
                          className="form-control"
                          id="ms_video"
                          onChange={(e) => {
                            const mstemp = {
                              ...ms,
                              videos: [...ms.videos, e.target.value],
                            };
                            setMS(mstemp);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-100 center admin-prodbtn-container">
                    <button
                      type="submit"
                      className="ci-btn-full-light-blue admin-add-button"
                    >
                      Save Videos
                    </button>
                  </div>
                </form>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMediaScreen;
