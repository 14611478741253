import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";

import { Link, useNavigate, useParams } from "react-router-dom";

import Rating from "./../Components/Common/Rating";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductReview,
  deleteProductReview,
  listProductDetails,
} from "../Redux/Actions/ProductActions";
import Loading from "./../Components/Common/Loading";
import ErrorMessage from "./../Components/Common/Error";
import { addToCart } from "./../Redux/Actions/CartActions";
import Message from "../Components/Common/Message";
import { getId } from "../Redux/Actions/UserActions";
import { Helmet } from "react-helmet";

const SingleProductScreen = () => {
  const [selected, setSelected] = useState("");
  const [qty, setQty] = useState(1);
  const [reviewActive, setReviewActive] = useState(false);
  const [naturalSize, setNaturalSize] = useState({ width: null, height: null });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const { _id } = useParams();

  const [customerName, setCustomerName] = useState("");
  const [customerRating, setCustomerRating] = useState(1);
  const [customerComment, setCutomerComment] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: rloading,
    error: rerror,
    success: rsuccess,
  } = productReviewCreate;

  const productReviewDelete = useSelector((state) => state.productReviewDelete);
  const {
    loading: rdloading,
    error: rderror,
    success: rdsuccess,
  } = productReviewDelete;

  useEffect(() => {
    dispatch(listProductDetails(_id));
  }, [dispatch, _id, rsuccess, rdsuccess]);

  useEffect(() => {
    if (product) {
      if (loading === false && product.image) {
        setSelected(product.image);
        let lightbox = new PhotoSwipeLightbox({
          gallery: "#" + "mediaGallery",
          children: "a",
          pswpModule: () => import("photoswipe"),
        });

        lightbox.init();
        return () => {
          lightbox.destroy();
          lightbox = null;
        };
      }
    }
  }, [product]);

  useEffect(() => {
    // Ditect user info
    let userId = JSON.parse(window.localStorage.getItem("userId"));
    if (!userId) {
      dispatch(getId());
      userId = JSON.parse(window.localStorage.getItem("userId"));
    }

    setCustomerID(userId.id);
    setReviewSubmitted(false);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + "mediaGallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });

    lightbox.init();
    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  const handleImageLoad = () => {
    setNaturalSize({
      width: imageRef.current.naturalWidth,
      height: imageRef.current.naturalHeight,
    });
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    if (product) {
      dispatch(addToCart(product._id, qty));
    }
    navigate("/cart");
  };
  const handleAddToCartBuy = (e) => {
    e.preventDefault();
    if (product) {
      dispatch(addToCart(product._id, qty));
    }
    navigate("/checkout");
  };

  const handleReviewSubmit = (id) => {
    dispatch(
      createProductReview(id, {
        id: customerID,
        name: customerName,
        rating: customerRating,
        comment: customerComment,
      })
    );
    setReviewSubmitted(true);
  };
  const handleDeleteReview = (id, revId) => {
    dispatch(deleteProductReview(id, revId));
  };

  return (
    <div className="ci-si-screen">
      <Navbar />
      {product && (
        <div className="ci-si-screen-container ci-first-section ci-container">
          <div className="row ">
            <div className="ci-si-display col-md-7 ci-si-col-l">
              <div className="ci-si-product-main">
                <div className="pswp-gallery" id="mediaGallery">
                  {loading ? (
                    <Loading color="orange" w="80px" h="80px" />
                  ) : error ? (
                    <ErrorMessage message={error} />
                  ) : (
                    <a
                      href={selected}
                      data-pswp-width={
                        imageRef.current && imageRef.current.complete
                          ? imageRef.current.naturalWidth
                          : naturalSize.width
                          ? naturalSize.width
                          : "900"
                      }
                      data-pswp-height={
                        imageRef.current && imageRef.current.complete
                          ? imageRef.current.naturalHeight
                          : naturalSize.height
                          ? naturalSize.height
                          : "900"
                      }
                    >
                      <img
                        src={selected}
                        ref={imageRef}
                        alt={selected}
                        id={selected}
                        onLoad={handleImageLoad}
                      />
                    </a>
                  )}
                </div>
              </div>
              {loading ? (
                <Loading color="orange" w="80px" h="80px" />
              ) : error ? (
                <ErrorMessage message={error} />
              ) : (
                <div className="ci-si-product-sub-container">
                  <Helmet>
                    <title>{`${product.name}`}</title>
                    <meta
                      name="description"
                      content={`${product.description}`}
                    />
                  </Helmet>
                  <img
                    src={product.image}
                    className={
                      selected === product.image ? "ci-si-selected" : ""
                    }
                    onClick={() => {
                      setSelected(product.image);
                    }}
                    alt={product.image}
                  />
                  {product.subimages &&
                    product.subimages.length > 0 &&
                    product.subimages.map((subimage, i) => (
                      <img
                        src={subimage}
                        onClick={() => {
                          setSelected(subimage);
                        }}
                        className={
                          selected === subimage ? "ci-si-selected" : ""
                        }
                        key={i}
                        alt={subimage}
                      />
                    ))}
                </div>
              )}
            </div>
            <div className="ci-si-info-container col-md-5 ci-si-col-r">
              <div className="ci-si-top-info">
                <h1 className="ci-si-product-name">{product.name}</h1>
                {product.oldprice && product.oldprice !== 0 ? (
                  <p className="ci-si-product-old-price">${product.oldprice}</p>
                ) : (
                  ""
                )}
                <p className="ci-si-product-price">${product.price}</p>
                {product.countInStock > 0 ? (
                  <p className="ci-si-product-quantity">
                    Quantity:{" "}
                    <span>
                      <select
                        className="ci-si-product-quantity-selector"
                        name="quantity"
                        id="quantity"
                        value={qty}
                        onChange={(e) => setQty(Number(e.target.value))}
                      >
                        {[...Array(product.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </select>
                    </span>
                  </p>
                ) : null}
                {product.countInStock > 0 ? (
                  <p className="ci-si-product-instock">Status: In Stock</p>
                ) : (
                  <p className="ci-si-product-notinstock ">
                    Status: Not In Stock.
                  </p>
                )}
              </div>
              {product.countInStock > 0 && (
                <div className="ci-si-bot-info">
                  <button
                    onClick={handleAddToCart}
                    className="ci-btn-full-light-blue"
                  >
                    Add to Cart <i className="fa fas-cart"></i>
                  </button>
                  <button
                    onClick={handleAddToCartBuy}
                    className="ci-btn-full-blue"
                  >
                    Buy Now
                  </button>
                </div>
              )}
            </div>
          </div>

          {loading ? (
            <Loading color="orange" w="60px" h="60px" />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <React.Fragment>
              <div className="ci-si-product-desc-container">
                <h2 className="ci-si-product-heading">Description</h2>
                <p className="ci-si-product-desc">{product.description}</p>
              </div>
              <div className="ci-si-product-reviews-container">
                <h2 className="ci-si-product-heading">Customer Reviews</h2>
                <div className="ci-si-product-reviews-gallery">
                  {product.reviews &&
                    product.reviews.length > 0 &&
                    product.reviews.map((review, i) => (
                      <div key={`${i}`} className="ci-product-review-container">
                        <div className="ci-si-review-rating-container">
                          <Rating value={review.rating} />
                          {customerID
                            ? customerID == review.id && (
                                <i
                                  onClick={(e) => {
                                    handleDeleteReview(product._id, review._id);
                                  }}
                                  className="fas fa-trash ci-si-review-delete-button"
                                ></i>
                              )
                            : ""}
                        </div>
                        <p className="ci-si-review-name">{review.name}</p>
                        <p className="ci-si-review-comment">{review.comment}</p>
                      </div>
                    ))}
                </div>
                <div className="ci-si-submit-review-container">
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      setReviewActive(!reviewActive);
                    }}
                    className="ci-si-submit-review-label-heading"
                  >
                    Write a Review
                  </p>
                  {reviewSubmitted ? (
                    <React.Fragment>
                      {rloading && <Loading color="orange" w="80px" h="80px" />}
                      {rerror && <ErrorMessage message={rerror} />}
                      {rsuccess && (
                        <Message
                          title="Review Submited"
                          message="Thanks for submitting a review!"
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <form
                      action="submit"
                      className={reviewActive ? "ci-si-review-active" : ""}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleReviewSubmit(product._id);
                      }}
                    >
                      <input
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        className="ci-si-submit-review-name"
                        type="text"
                        placeholder="Enter your name here."
                      />
                      <p className="ci-si-submit-review-rating">
                        Rating:{" "}
                        <select
                          value={customerRating}
                          onChange={(e) =>
                            setCustomerRating(Number(e.target.value))
                          }
                        >
                          <option value="0">1</option>
                          <option value="1">1</option>
                          <option value="1.5">1.5</option>
                          <option value="2">2</option>
                          <option value="2.5">2.5</option>
                          <option value="3">3</option>
                          <option value="3.5">3.5</option>
                          <option value="4">4</option>
                          <option value="4.5">4.5</option>
                          <option value="5">5</option>
                        </select>
                      </p>
                      <label
                        className="ci-si-submit-review-comment-heading"
                        htmlFor="review"
                      >
                        Comment:
                      </label>
                      <textarea
                        value={customerComment}
                        onChange={(e) => setCutomerComment(e.target.value)}
                        className="ci-si-submit-review-comment"
                        name="review"
                        id="review"
                        rows="4"
                        placeholder="Write your comment here."
                      ></textarea>
                      <button className="ci-btn-full-blue">
                        Submit Review
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default SingleProductScreen;
