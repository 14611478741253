import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <div
      style={{
        background: "#ffb6b6",
        borderRadius: "25px",
        padding: "20px 30px",
      }}
      className="ci-error-container"
    >
      <p
        style={{
          fontSize: "larger",
          fontFamily: "sans-serif",
          color: "darkred",
          fontWeight: "bold",
        }}
      >
        Error
      </p>
      <hr style={{ color: "red" }} />
      <p
        style={{
          fontSize: "medium",
          fontFamily: "sans-serif",
          color: "darkred",
        }}
      >
        Oops, an error occurred: <b>{message}</b>
      </p>
    </div>
  );
};

export default ErrorMessage;
