import React, { useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "./PortalApp.css";
import "react-toastify/dist/ReactToastify.css";
import packageJson from "../package.json";
import { Route, Routes } from "react-router";
import { useNavigate, Navigate } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import ProductsScreen from "./Screens/ProductsScreen";
import MediaScreen from "./Screens/MediaScreen";
import ContactScreen from "./Screens/ContactScreen";
import SingleProductScreen from "./Screens/SingleProductScreen";
import CartScreen from "./Screens/CartScreen";
import OrderScreen from "./Screens/OrderScreen";
import PrivateRoute from "./PrivateRoute";
import AdminLoginScreen from "./Screens/Admin/AdminLoginScreen";
import AdminHomeScreen from "./Screens/Admin/AdminHomeScreen";
import AdminProductsScreen from "./Screens/Admin/AdminProductsScreen";
import AdminAddProductScreen from "./Screens/Admin/AdminAddProductScreen";
import AdminProductDetailsScreen from "./Screens/Admin/AdminProductDetailsScreen";
import AdminControlsScreen from "./Screens/Admin/AdminControlsScreen";
import AdminMediaScreen from "./Screens/Admin/AdminMediaScreen";
import AdminOrdersScreen from "./Screens/Admin/AdminOrdersScreen";
import AdminOrderDetailsScreen from "./Screens/Admin/AdminOrderDetailsScreen";
import TrackingDetailsScreen from "./Screens/TrackingDetailsScreen";
import { useDispatch } from "react-redux";
import { getId } from "./Redux/Actions/UserActions";
import TrackingScreen from "./Screens/TrackingScreen";
import CheckoutStatusScreen from "./Screens/CheckoutStatusScreen";
import CheckoutInPersonStatusScreen from "./Screens/CheckoutInPersonStatusScreen";
import NotFoundScreen from "./Screens/NotFoundScreen";
import AdminRestockScreen from "./Screens/Admin/AdminRestockScreen";
import Helmet from "react-helmet";
import AdminInPersonOrderDetailsScreen from "./Screens/Admin/AdminInPersonOrderDetailsScreen";

function App() {
  //cacheBuster3000
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let version = localStorage.getItem("version");
    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
    dispatch(getId());
  }, []);

  useEffect(() => {
    dispatch(getId());
  }, [dispatch, navigate]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Berly Essentials</title>
        <meta
          name="description"
          content="Your #1 All Natural Beauty Hair Shop Stop!"
        />
        <meta
          name="keywords"
          content="Hair Products, Trinidad Hair Products, Argan Oil, Beauty Store, Beauty Store Trinidad"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/products/:filter?" element={<ProductsScreen />} />
        <Route path="/media" element={<MediaScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/product/:_id" element={<SingleProductScreen />} />
        <Route path="/cart" element={<CartScreen />} />
        <Route path="/checkout" element={<OrderScreen />} />
        <Route path="/checkout/:id" element={<CheckoutStatusScreen />} />
        <Route
          path="/checkoutInPerson/:id"
          element={<CheckoutInPersonStatusScreen />}
        />
        <Route path="/tracking" element={<TrackingScreen />} />
        <Route path="/tracking/:id" element={<TrackingDetailsScreen />} />
        {/* Portal Routes */}
        <Route path="/admin/" element={<PrivateRoute />}>
          <Route path="/admin/" element={<AdminHomeScreen />} />
        </Route>
        <Route path="/admin/products/" element={<PrivateRoute />}>
          <Route path="/admin/products/" element={<AdminProductsScreen />} />
        </Route>
        <Route path="/admin/products/:id" element={<PrivateRoute />}>
          <Route
            path="/admin/products/:id"
            element={<AdminProductDetailsScreen />}
          />
        </Route>
        <Route path="/admin/products/add" element={<PrivateRoute />}>
          <Route
            path="/admin/products/add"
            element={<AdminAddProductScreen />}
          />
        </Route>
        <Route path="/admin/controls" element={<PrivateRoute />}>
          <Route path="/admin/controls" element={<AdminControlsScreen />} />
        </Route>
        <Route path="/admin/media" element={<PrivateRoute />}>
          <Route path="/admin/media" element={<AdminMediaScreen />} />
        </Route>
        <Route path="/admin/orders" element={<PrivateRoute />}>
          <Route path="/admin/orders" element={<AdminOrdersScreen />} />
        </Route>
        <Route path="/admin/restock" element={<PrivateRoute />}>
          <Route path="/admin/restock" element={<AdminRestockScreen />} />
        </Route>
        <Route path="/admin/orders/:id" element={<PrivateRoute />}>
          <Route
            path="/admin/orders/:id"
            element={<AdminOrderDetailsScreen />}
          />
        </Route>
        <Route path="/admin/orders/inperson/:id" element={<PrivateRoute />}>
          <Route
            path="/admin/orders/inperson/:id"
            element={<AdminInPersonOrderDetailsScreen />}
          />
        </Route>
        <Route path="/admin/comments" element={<PrivateRoute />}>
          <Route path="/admin/comments" element={<AdminHomeScreen />} />
        </Route>
        {/*login */}
        <Route path="/admin/login" element={<AdminLoginScreen />} />
        {/* 404 */}
        <Route path="/404" element={<NotFoundScreen />} />
        <Route path="/*" element={<Navigate to="/404" />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
