import React from "react";
import { Link } from "react-router-dom";
const EditIcon = ({ link }) => {
  return (
    <div>
      <Link to={link} className="admin-edit-icon-container">
        <i className="fa fa-pen admin-pencil-icon admin-edit-icon"></i>
      </Link>
    </div>
  );
};

export default EditIcon;
