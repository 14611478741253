import React, { useEffect, useState } from "react";
import Toast from "../../Components/Toast";
import AdminHeader from "../../Components/admin/AdminHeader";
import AdminSidebar from "../../Components/admin/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listOrder } from "../../Redux/Actions/OrderActions";
import Loading from "../../Components/Common/Loading";
import ErrorMessage from "../../Components/Common/Error";

const AdminOrdersScreen = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("In Person");
  const [payfilter, setPayFilter] = useState("false");
  const [displayOrders, setDisplayOrders] = useState([]);
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  useEffect(() => {
    let del = "";
    let pay = "true";
    if (filter == "All") {
      del = "";
    } else {
      del = filter;
    }

    if (payfilter == "true") {
      pay = "false";
    } else {
      pay = "true";
    }

    if (orders) {
      if (search) {
        setDisplayOrders(
          orders.filter(
            (order) =>
              order.deliveryStatus.toLocaleLowerCase().includes(del) &&
              (order.customer.fname
                .toLowerCase()
                .includes(search.toLowerCase()) ||
                order.customer.lname
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                order.customer.email
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                String(order.totalPrice)
                  .toLowerCase()
                  .includes(search.toLowerCase()))
          )
        );
      } else {
        console.log("del", del.toLocaleLowerCase());
        orders.map((order) => {
          console.log("status", order.deliveryStatus.toLocaleLowerCase());
          console.log(
            "pay",
            order.deliveryStatus
              .toLocaleLowerCase()
              .includes(del.toLocaleLowerCase())
          );
        });
        setDisplayOrders(
          orders.filter(
            (order) =>
              order.deliveryStatus
                .toLocaleLowerCase()
                .includes(del.toLocaleLowerCase()) &&
              String(order.isPaid).toLocaleLowerCase().includes(pay)
          )
        );
      }
    }
  }, [search, orders, filter, payfilter]);

  useEffect(() => {
    dispatch(listOrder());
  }, [dispatch]);

  return (
    <div>
      <AdminHeader />
      <Toast />
      <div className="interface-container">
        <AdminSidebar />
        <div className="main-display">
          <div className="admin-main-container">
            <h2 className="admin-header admin-inline">Order Screen</h2>
            <div className="admin-order-tabs-wrapper">
              <div className="admin-order-tabs-container">
                <div
                  className={`admin-order-tab ${
                    filter === "In Person" && payfilter === "false"
                      ? "admin-active"
                      : ""
                  }`}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFilter("In Person");
                      setPayFilter("false");
                    }}
                  >
                    New Pay In Person Orders
                  </button>
                </div>
                <div
                  className={`admin-order-tab ${
                    filter === "Preparing Order" && payfilter === "false"
                      ? "admin-active"
                      : ""
                  }`}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFilter("Preparing Order");
                      setPayFilter("false");
                    }}
                  >
                    New Credit Card Orders
                  </button>
                </div>

                <div
                  className={`admin-order-tab ${
                    filter === "Out for Delivery" ? "admin-active" : ""
                  }`}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFilter("Out for Delivery");
                      setPayFilter("false");
                    }}
                  >
                    Delivering
                  </button>
                </div>
                <div
                  className={`admin-order-tab ${
                    filter === "Delivered" ? "admin-active" : ""
                  }`}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFilter("Delivered");
                      setPayFilter("false");
                    }}
                  >
                    Delivered
                  </button>
                </div>
                <div
                  className={`admin-order-tab ${
                    filter === "All" ? "admin-active" : ""
                  }`}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFilter("All");
                      setPayFilter("false");
                    }}
                  >
                    All
                  </button>
                </div>
                <div
                  className={`admin-order-tab ${
                    payfilter === "true" ? "admin-active" : ""
                  }`}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFilter("Preparing Order");
                      setPayFilter("true");
                    }}
                  >
                    Unpaid/Unsucessful
                  </button>
                </div>
              </div>
            </div>
            <div className="admin-search-container">
              <input
                className="admin-items-searchbar mb-4"
                type="text"
                placeholder="Search for an order"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              <React.Fragment>
                <div className={"admin-orders-container"}>
                  {displayOrders.map((order) => (
                    <div
                      key={order._id}
                      className={`admin-order-container ${
                        !order.isPaid
                          ? "ci-orders-unsuccessful"
                          : order.deliveryStatus === "Preparing Order"
                          ? "ci-orders-preparing"
                          : order.deliveryStatus === "Out for Delivery"
                          ? "ci-orders-delivery"
                          : "ci-orders-delivered"
                      } ${
                        !order.isInPerson
                          ? ""
                          : order.deliveryStatus === "Delivered"
                          ? ""
                          : "ci-orders-inperson"
                      }`}
                    >
                      <div className="admin-order-inner-container">
                        <div className="admin-order-inner-top">
                          <p className="admin-order-name">
                            {order.customer.fname} {order.customer.lname}
                          </p>
                          <span className="admin-order-id">{order._id}</span>
                        </div>
                        <div className="admin-order-inner-bottom">
                          <table className="">
                            <thead></thead>
                            <tbody>
                              <tr>
                                <th>Total Price</th>
                                <td>{order.totalPrice}</td>
                              </tr>
                              <tr>
                                <th>No. Items</th>
                                <td>{order.orderItems.length}</td>
                              </tr>
                              <tr>
                                <th>Delivery Status</th>
                                <td>
                                  {order.isDelivered
                                    ? "Delivered"
                                    : "Not Delivered"}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  {order.isInPerson == true ? (
                                    <Link
                                      to={`/admin/orders/inperson/${order._id}`}
                                    >
                                      <b>View/Edit In Person Order</b>
                                    </Link>
                                  ) : (
                                    <Link to={`/admin/orders/${order._id}`}>
                                      <b>View/Edit Order</b>
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrdersScreen;
