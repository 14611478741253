import React, { useEffect, useState } from "react";
import { ourProductSettings } from "../../Data/pagecontrols";
import { useSelector } from "react-redux";

const OurProduct = () => {
  const [ourProductControls, setOurProductControls] = useState({
    ...ourProductSettings,
  });

  const controlList = useSelector((state) => state.controlList);
  const { controls } = controlList;

  useEffect(() => {
    if (controls && controls.length >= 1) {
      const ourProductControlsTemp = controls[0]
        ? { ...controls[0].ourProductSettings }
        : { ...ourProductSettings };
      setOurProductControls({ ...ourProductControlsTemp });
    } else {
      setOurProductControls({ ...ourProductSettings });
    }
  }, [controls]);

  return (
    <div className="ci-ourproduct-container ci-section">
      <div className="ci-ourproduct-inner-container">
        <div className="ci-ourproduct-info">
          <div className="ci-ourproduct-heading-container">
            <h2 className="ci-ourproduct-heading">Our Product</h2>
          </div>
          <div className="ci-ourproduct-description-container">
            <p className="ci-ourproduct-description">
              {ourProductControls.description_a}
            </p>
            <br />
            <p className="ci-ourproduct-description">
              {ourProductControls.description_b}
            </p>
          </div>
        </div>
        <div className="ci-ourproduct-image-container">
          <img
            className="ci-ourproduct-image"
            src="/images/Model3.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default OurProduct;
