import React, { forwardRef, useEffect, useState } from "react";
import Slider from "react-slick";
import { settings } from "../../Settings/ReactSlick";
import { homeSettings } from "../../Data/pagecontrols";
import { useNavigate } from "react-router";
import { AttentionSeeker } from "react-awesome-reveal";
import { useSelector } from "react-redux";

const Banner = forwardRef((props, ref) => {
  const [homeControls, setHomeControls] = useState({ ...homeSettings });
  const navigate = useNavigate();
  const controlList = useSelector((state) => state.controlList);
  const { controls } = controlList;

  useEffect(() => {
    if (controls && controls.length >= 1) {
      const homeControlsTemp = controls[0]
        ? { ...controls[0].homeSettings }
        : { ...homeSettings };
      setHomeControls({ ...homeControlsTemp });
    } else {
      setHomeControls({ ...homeSettings });
    }
  }, [controls]);
  //const bannerImages = ["/images/Model1.png", "/images/Model2.png"];

  return (
    <div className="ci-banner-container first-section-mobile" ref={ref}>
      <Slider {...settings} className="mobile-hidden">
        <div className="row ci-banner-container-row ">
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-l">
            <div className="ci-banner-text-container">
              <AttentionSeeker effect="pulse" fraction={0} duration={1000}>
                <p className="ci-banner-maintitle">
                  {homeControls.bannerHeading_a}
                </p>
              </AttentionSeeker>
              <p className="ci-banner-subtitle">
                {homeControls.bannerSubHeading_a}
              </p>
            </div>

            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/products/");
              }}
              className="ci-solid-btn-orange ci-btn-small ci-banner-btn"
            >
              {homeControls.bannerButton_a}
            </button>
          </div>
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-r">
            <img src={homeControls.bannerImage_a} alt="" />
          </div>
        </div>
        <div className="row ci-banner-container-row ">
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-r ci-banner-col-opp">
            <img src={homeControls.bannerImage_b} alt="" />
          </div>
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-l">
            <div className="ci-banner-text-container">
              <AttentionSeeker effect="pulse" fraction={0} duration={1000}>
                <p className="ci-banner-maintitle">
                  {homeControls.bannerHeading_b}
                </p>
              </AttentionSeeker>
              <p className="ci-banner-subtitle">
                {homeControls.bannerSubHeading_b}
              </p>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/products/");
              }}
              className="ci-solid-btn-orange ci-btn-small ci-banner-btn"
            >
              {homeControls.bannerButton_b}
            </button>
          </div>
        </div>
      </Slider>
      {/*Mobile Slider*/}
      <Slider {...settings} className="mobile-show">
        <div className="row ci-banner-container-row ">
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-l">
            <div className="ci-banner-text-container">
              <AttentionSeeker effect="pulse" fraction={0} duration={1000}>
                <p className="ci-banner-maintitle">
                  {homeControls.bannerHeading_a}
                </p>
              </AttentionSeeker>
              <p className="ci-banner-subtitle">
                {homeControls.bannerSubHeading_a}
              </p>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/products/");
              }}
              className="ci-solid-btn-orange ci-btn-small ci-banner-btn"
            >
              {homeControls.bannerButton_a}
            </button>
          </div>
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-r">
            <img src={homeControls.bannerImage_a} alt="" />
          </div>
        </div>
        <div className="row ci-banner-container-row ">
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-l">
            <div className="ci-banner-text-container">
              <AttentionSeeker effect="pulse" fraction={0} duration={1000}>
                <p className="ci-banner-maintitle">
                  {homeControls.bannerHeading_b}
                </p>
              </AttentionSeeker>
              <p className="ci-banner-subtitle">
                {homeControls.bannerSubHeading_b}
              </p>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/products/");
              }}
              className="ci-solid-btn-orange ci-btn-small ci-banner-btn"
            >
              {homeControls.bannerButton_b}
            </button>
          </div>
          <div className="col-lg-6 ci-banner-container-col ci-banner-col-r">
            <img src={homeControls.bannerImage_b} alt="" />
          </div>
        </div>
      </Slider>
    </div>
  );
});

export default Banner;
