import React, { useEffect, useRef } from "react";
import Navbar from "./../Components/Common/Navbar";
import Banner from "../Components/Home/Banner";
import Trending from "../Components/Home/Trending";
import NewestAdditions from "./../Components/Home/NewestAdditions";
import OurProduct from "../Components/Home/OurProduct";
import Footer from "../Components/Common/Footer";
import { useDispatch } from "react-redux";
import { listProduct } from "../Redux/Actions/ProductActions";
import { listControls } from "./../Redux/Actions/ControlActions";

const HomeScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    dispatch(listProduct());
    dispatch(listControls());
  }, []);

  const bannerRef = useRef(null);
  return (
    <React.Fragment>
      <Navbar bannerRef={bannerRef} />
      <Banner ref={bannerRef} />
      <Trending />
      <NewestAdditions />
      <OurProduct />
      <Footer />
    </React.Fragment>
  );
};

export default HomeScreen;
