import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { Link, useParams } from "react-router-dom";
import { listUserOrder } from "../Redux/Actions/OrderActions";
import Loading from "../Components/Common/Loading";
import moment from "moment";
import { Helmet } from "react-helmet";

const TrackingScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const orderDetailState = useSelector((state) => state.orderUserList);
  const { loading, error, userOrders: orders } = orderDetailState;

  useEffect(() => {
    dispatch(listUserOrder());
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Track Orders</title>
        <meta name="description" content="Track your orders here" />
      </Helmet>
      <Navbar />
      <div className="ci-tracking-screen-container ci-first-section ci-container">
        <div className="ci-tracking-screen-heading">
          <h2>My Orders</h2>
          {loading ? (
            <Loading color="orange" w="60px" h="60px" />
          ) : error || (orders && orders.length === 0) ? (
            <React.Fragment>
              <h3 className="mt-2">No orders found. Enjoy Shopping with Us!</h3>
              <Link className="admin-header-backlink" to="/">
                <i className="fas fa-arrow-left"></i> Click to Back
              </Link>
            </React.Fragment>
          ) : orders ? (
            <div className="ci-tracking-screen-tray">
              {orders.map((order) => (
                <div
                  key={order._id}
                  className={`admin-order-container ${
                    !order.isPaid
                      ? "ci-orders-unsuccessful"
                      : order.deliveryStatus === "Preparing Order"
                      ? "ci-orders-preparing"
                      : order.deliveryStatus === "Out for Delivery"
                      ? "ci-orders-delivery"
                      : order.deliveryStatus === "Preparing In Person Order"
                      ? "ci-orders-preparing-in-person"
                      : "ci-orders-delivered"
                  }`}
                >
                  <div className="admin-order-inner-container">
                    <div className="admin-order-inner-top">
                      <p className="admin-order-name">
                        {order.customer.fname} {order.customer.lname}
                      </p>
                      <span className="admin-order-id">{order._id}</span>
                    </div>
                    <div className="admin-order-inner-bottom">
                      <table className="">
                        <thead></thead>
                        <tbody>
                          <tr>
                            <th>Total Price</th>
                            <td>{order.totalPrice}</td>
                          </tr>
                          <tr>
                            <th>No. Items</th>
                            <td>{order.orderItems.length}</td>
                          </tr>
                          <tr>
                            <th>Delivery Status</th>
                            <td>
                              {order.isDelivered
                                ? "Delivered"
                                : "Not Delivered"}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <Link to={`/tracking/${order._id}`}>
                                <b>View/Edit Order</b>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div class="p-5">You have No Orders</div>
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default TrackingScreen;
