import React, { useEffect, useState } from "react";
import Product from "../Common/Product";
import Slider from "react-slick";
import { productSettings } from "../../Settings/ReactSlick";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Fade, Slide, Bounce } from "react-awesome-reveal";
import Loading from "../Common/Loading";
import ErrorMessage from "../Common/Error";

const NewestAdditions = () => {
  const [productShowcase, setProductShowcase] = useState([]);
  const navigate = useNavigate();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    if (
      products &&
      JSON.stringify(products) !== "[]" &&
      JSON.stringify(products) !== "[{}]"
    ) {
      setProductShowcase([...products]);
    }
  }, [products]);

  return (
    <div className="ci-new-container ci-container ci-section">
      <div className="ci-new-heading">
        <Slide duration={500} direction="right">
          <h2>Newest Additions</h2>
        </Slide>
      </div>
      <div className="ci-new-showcase mobile-hidden">
        {loading ? (
          <Loading color="deep-blue" w="60px" h="60px" />
        ) : error ? (
          <ErrorMessage message={error} />
        ) : (
          productShowcase
            .slice(-6)
            .reverse()
            .map((product, i) => (
              <div key={i} className="ci-new-product-container">
                <Product
                  id={product._id}
                  name={product.name}
                  price={product.price}
                  buttonlabel="Add to Cart"
                  promotext={product.promotext}
                  promocolor="ci-promo-deepblue"
                  img={product.image}
                  count={product.countInStock}
                />
              </div>
            ))
        )}
      </div>
      <div className="ci-new-gallery mobile-show">
        {productShowcase && (
          <Slider
            {...productSettings}
            infinite={
              10 > productSettings.slidesToShow &&
              productShowcase.length > productSettings.slidesToShow
            }
          >
            {productShowcase
              .slice(-6)
              .reverse()
              .map((product, i) => (
                <Product
                  key={i}
                  name={product.name}
                  price={product.price}
                  buttonlabel="Add to Cart"
                  promotext={product.promotext}
                  promocolor="ci-promo-deepblue"
                  img={product.image}
                  id={product._id}
                  count={product.countInStock}
                />
              ))}
          </Slider>
        )}
      </div>
      <div className="ci-new-button-container">
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate("/products/");
          }}
          className="ci-solid-btn-deepblue ci-btn-small"
        >
          View All
        </button>
      </div>
    </div>
  );
};

export default NewestAdditions;
