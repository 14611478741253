import React, { useEffect, useState } from "react";
import Product from "./Product";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import ErrorMessage from "./Error";
import ReactPaginate from "react-paginate";
// import { products } from "../../Data/products";

const Gallery = ({ search }) => {
  const [productGallery, setProductGallery] = useState([]);
  const [filter, setFilter] = useState("");
  const [types, setTypes] = useState([]);
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    if (products) {
      let tempGallery = [...products];
      if (filter.length) {
        tempGallery = [
          ...tempGallery.filter((product) =>
            product.type.toLowerCase().includes(filter.toLowerCase())
          ),
        ];
      }

      if (search) {
        tempGallery = [
          ...tempGallery.filter(
            (product) =>
              product.name.toLowerCase().includes(search.toLowerCase()) ||
              product.price.toString().includes(search.toLowerCase()) ||
              product.description.toLowerCase().includes(search.toLowerCase())
          ),
        ];
      }
      setProductGallery(tempGallery);

      let acc = types.length > 0 ? [...types] : [];
      tempGallery.map((i) => {
        if (acc.includes(i.type)) {
        } else {
          acc = [...acc, i.type];
        }
      });
      setTypes([...acc]);
      setItemOffset(0);
    }
  }, [products, search, filter]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(productGallery.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(productGallery.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, productGallery]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % productGallery.length;
    setItemOffset(newOffset);
  };

  return (
    <React.Fragment>
      <div className="ci-gallery">
        {loading ? (
          <Loading color="orange" w="60px" h="60px" />
        ) : error ? (
          <ErrorMessage message={error} />
        ) : (
          <React.Fragment>
            <div className="ci-gallery-filter-container">
              <div className="ci-gallery-filter-container-inner">
                <i className="fas fa-filter ci-gallery-search-icon"></i>
                Filter:
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="ci-gallery-select"
                >
                  <option value="">all</option>
                  {types.map((t, i) => (
                    <option key={i}>{t}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="ci-gallery-grid">
              {currentItems &&
                currentItems.map((product, i) => (
                  <div key={i} className="ci-gallery-product-container">
                    <Product
                      id={product._id}
                      name={product.name}
                      price={product.price}
                      buttonlabel="Add to Cart"
                      promotext={product.promotext}
                      promocolor="ci-promo-orange"
                      img={product.image}
                      count={product.countInStock}
                    />
                  </div>
                ))}
            </div>
          </React.Fragment>
        )}
      </div>
      {currentItems && (
        <React.Fragment>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="ci-gallery-pagination"
            pageLinkClassName="ci-gallery-page-num"
            previousLinkClassName="ci-gallery-page-num"
            nextLinkClassName="ci-gallery-page-num"
            activeLinkClassName="ci-gallery-page-active"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Gallery;
