import React, { useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { contactSettings } from "../Data/pagecontrols";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import url from "../URL";
import axios from "axios";
import { listControls } from "../Redux/Actions/ControlActions";
import { Helmet } from "react-helmet";

const ContactScreen = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [contactControls, setContactControls] = useState({
    ...contactSettings,
  });
  const controlList = useSelector((state) => state.controlList);
  const { controls } = controlList;

  useEffect(() => {
    if (controls && controls.length >= 1) {
      const contactControlsTemp = controls[0]
        ? { ...controls[0].contactSettings }
        : { ...contactSettings };
      setContactControls({ ...contactControlsTemp });
    } else {
      setContactControls({ ...contactSettings });
    }
  }, [controls]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    dispatch(listControls());
  }, []);

  const handleSubmit = (e) => {
    const messageToSend = message === "" ? e.target.message.value : message;
    e.preventDefault();
    axios
      .post(`${url}/api/mail/`, {
        firstName,
        lastName,
        email,
        tel,
        message: messageToSend,
      })
      .then((res) => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setTel("");
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Get in touch with us" />
      </Helmet>
      <Navbar />
      <div className="row ci-contact-screen-container ci-first-section ci-container">
        <div className="ci-contact-info col-lg-6 ci-contact-col">
          {/* <h2 className="ci-contact-screen-heading ci-first-heading">
            Contact
          </h2>
          <p>{contactSettings.phone}</p>
          <button className="ci-solid-btn-blue ci-contact-call-btn">
            Call Now
          </button> */}
          <h2 className="ci-contact-screen-heading ci-first-heading">
            Social Media
          </h2>
          <div className="ci-contact-icons-container ">
            <Link to={contactControls.facebook}>
              <i className="fab fa-facebook"></i>
            </Link>
            <Link to={contactControls.instagram}>
              <i className="fab fa-instagram"></i>
            </Link>
            {/* <Link>
              <i className="fab fa-whatsapp"></i>
            </Link> */}
          </div>
        </div>
        <div className="ci-contact-feedback col-lg-6 ci-contact-col">
          <h2 className="ci-contact-screen-heading ci-first-heading">
            Send Us Feedback
          </h2>
          <form action="submit" onSubmit={handleSubmit}>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              placeholder="Enter First Name"
            />
            <input
              type="text"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter Last Name"
            />
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
            />
            <input
              type="tel"
              required
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              placeholder="Enter Phone Number"
              name=""
              id=""
            />
            <textarea
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter Message"
              name=""
              id=""
              rows="3"
            />
            <button className="ci-solid-btn-orange" type="submit">
              Submit Feedback
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ContactScreen;
