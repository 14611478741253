import React, { useEffect, useState } from "react";
import Toast from "../../Components/Toast";
import AdminHeader from "../../Components/admin/AdminHeader";
import AdminSidebar from "../../Components/admin/AdminSidebar";
import Product from "../../Components/Common/Product";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../../Redux/Actions/ProductActions.js";
import DeleteIcon from "../../Components/admin/DeleteIcon";
import Loading from "../../Components/Common/Loading";
import ErrorMessage from "../../Components/Common/Error";

const AdminRestockScreen = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [displayProducts, setDisplayProducts] = useState([]);
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    if (products) {
      if (search) {
        setDisplayProducts(
          products.filter(
            (product) =>
              (product.name.toLowerCase().includes(search.toLowerCase()) ||
                String(product.price)
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                product.description
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                product.promotext
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                product.type.toLowerCase().includes(search.toLowerCase())) &&
              product.countInStock < 1
          )
        );
      } else {
        setDisplayProducts(
          products.filter((product) => product.countInStock < 1)
        );
      }
    }
  }, [search, products]);

  useEffect(() => {
    dispatch(listProduct());
  }, [dispatch]);

  return (
    <div>
      <AdminHeader />
      <Toast />
      <div className="interface-container">
        <AdminSidebar />
        <div className="main-display">
          <div className="admin-main-container">
            <h2 className="admin-header">Items In Need of Restocking</h2>
            <div className="admin-search-container">
              <input
                className="admin-items-searchbar mb-4"
                type="text"
                placeholder="Search for a product"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              <React.Fragment>
                <div className="content-container">
                  <div className="admin-items-container">
                    {displayProducts.map((product) => (
                      <div key={product._id} className="admin-items-card">
                        <Product
                          id={product._id}
                          name={product.name}
                          price={product.price}
                          buttonlabel="Add to Cart"
                          promotext={product.promotext}
                          promocolor="ci-promo-orange"
                          img={product.image}
                          removebutton="true"
                          editable={true}
                          count={product.countInStock}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRestockScreen;
