import React, { useEffect } from "react";
import Toast from "./../../Components/Toast";
import AdminHeader from "./../../Components/admin/AdminHeader";
import AdminSidebar from "./../../Components/admin/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Common/Loading";
import { listProduct } from "../../Redux/Actions/ProductActions";
import { Link } from "react-router-dom";

const AdminHomeScreen = () => {
  const dispatch = useDispatch();
  const itemList = useSelector((state) => state.productList);
  const { loading, products: items } = itemList;

  useEffect(() => {
    dispatch(listProduct());
  }, [dispatch]);

  return (
    <div>
      <AdminHeader />
      <Toast />
      <div className="interface-container">
        <AdminSidebar />
        <div className="main-display">
          <div className="admin-main-container">
            <h2 className="admin-header">Dashboard</h2>
            <div className="dashboard-container">
              <div className="main-admin-stats-container row">
                <div className="main-graph-container col-lg-8 col-md-12">
                  <h3>Current Items</h3>
                  <iframe
                    title="admin-main-chart"
                    className="admin-main-chart"
                    style={{
                      background: "#FFFFFF",
                      border: "none",
                      borderRadius: "2px",
                      boxShadow: "2px 2px 4px rgb(167, 167, 167)",
                    }}
                    src="https://charts.mongodb.com/charts-caramel-isle-tojby/embed/charts?id=645dd0e5-5de9-4c88-8ef5-72f7cb159f38&maxDataAge=3600&theme=light&autoRefresh=true"
                  ></iframe>
                  <iframe
                    title="admin-main-chart"
                    className="admin-main-chart"
                    style={{
                      background: "#FFFFFF",
                      border: "none",
                      borderRadius: "2px",
                      boxShadow: "2px 2px 4px rgb(167, 167, 167)",
                    }}
                    src="https://charts.mongodb.com/charts-caramel-isle-tojby/embed/charts?id=6475c700-86bb-4bf6-8da9-31fccc0ab72e&maxDataAge=3600&theme=light&autoRefresh=true"
                  ></iframe>
                  <iframe
                    title="admin-main-chart"
                    className="admin-main-chart"
                    style={{
                      background: "#FFFFFF",
                      border: "none",
                      borderRadius: "2px",
                      boxShadow: "2px 2px 4px rgb(167, 167, 167)",
                    }}
                    src="https://charts.mongodb.com/charts-caramel-isle-tojby/embed/charts?id=6475c80e-228e-482a-8763-06cc36f9110b&maxDataAge=3600&theme=light&autoRefresh=true"
                  ></iframe>
                </div>
                <div className="dashboard-r-container col-lg-4 col-md-12">
                  <div className="top-cards">
                    <div className="card">
                      <h3 className="card-title">Number of Items</h3>
                      {loading ? (
                        <Loading color="orange" w="60px" h="60px" />
                      ) : (
                        items && (
                          <p className="admin-card-content">{items.length}</p>
                        )
                      )}
                    </div>
                    <div style={{ marginTop: "30px" }} className="card">
                      <h3 className="card-title">Items to Restock</h3>
                      {loading ? (
                        <Loading color="orange" w="60px" h="60px" />
                      ) : (
                        items && (
                          <div className="admin-card-wrap-restock">
                            <p className="admin-card-content">
                              {items.filter((i) => i.countInStock < 1).length}
                            </p>
                            {items.filter((i) => i.countInStock < 1).length >
                              0 && (
                              <Link
                                to="/admin/restock"
                                className="ci-btn-full-light-blue admin-restock-button"
                              >
                                Restock
                              </Link>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    {/*                     <div className="card">
                      <h3 className="card-title">Number of Requests</h3>
                      <p className="admin-card-content">2</p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="admin-dashboard-bottom-container col-lg-12">
                {/* <h3 className="card-title">Recent Requests</h3> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHomeScreen;
