import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../../Redux/Actions/CartActions";
import DeleteIcon from "../admin/DeleteIcon";
import EditIcon from "../admin/EditIcon";

const Product = ({
  id,
  name,
  price,
  buttonlabel,
  promotext,
  promocolor,
  img,
  removebutton,
  editable,
  count,
}) => {
  // Remove add button
  let remove = false;
  removebutton ? (remove = true) : (remove = false);
  count < 1 ? (remove = true) : (remove = false);
  // Attempt at text scrolling on hover
  const containerRef = useRef(null);
  const deleteButton = editable ? true : false;
  const editButton = editable ? true : false;
  const handleHover = () => {
    const container = containerRef.current;
    if (container.scrollWidth > container.offsetWidth) {
      container.classList.add("overflowing");
    }
  };

  const handleLeave = () => {
    const container = containerRef.current;
    container.classList.remove("overflowing");
  };
  //
  const dispatch = useDispatch();
  const handleAddToCart = (id) => {
    dispatch(addToCart(id, 1));
    navigate("/cart");
  };
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="buttons-edit-icon-container">
        {deleteButton && (
          <DeleteIcon link={"/admin/products"} itemId={id} name={name} />
        )}
        {editButton && <EditIcon link={`/admin/products/${id}`} itemId={id} />}
      </div>
      <div
        className="ci-product-container"
        onClick={(e) => {
          navigate(`/product/${id}`);
        }}
      >
        <div className="ci-product-inner-container">
          {promotext && (
            <div className={`ci-product-promo-container ${promocolor}`}>
              <p className="ci-product-promo-text">{promotext}</p>
            </div>
          )}
          <div
            className="ci-product-info"
            ref={containerRef}
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}
          >
            <div className="ci-product-image">
              <img src={img} alt="product" />
            </div>
            <div className="ci-product-details">
              <p className="ci-product-name">{name}</p>
              <p className="ci-product-cost">${price} TTD</p>
              {remove ? (
                <div style={{ height: "30px" }}> </div>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(id);
                  }}
                  className="ci-product-button ci-solid-btn-underline ci-solid-btn-underline-blue"
                >
                  {buttonlabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Product;
