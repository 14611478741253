import React from "react";
import ReactLoading from "react-loading";
const Loading = ({ type, color, w, h }) => {
  let cLoad = "#cf5874";
  switch (color) {
    case "blue":
      cLoad = "#0e0f26";
      break;
    case "light-blue":
      cLoad = "#5971ff";
      break;
    case "pink":
      cLoad = "#ffa7bc";
      break;
    case "orange":
      cLoad = "#ffc683";
      break;
    case "dark-orange":
      cLoad = "#e4953a";
      break;
    case "lavender":
      cLoad = "#816dbe";
      break;
    case "deep-blue":
      cLoad = "#043c54";
      break;
    case "dark-pink":
      cLoad = "#cf5874";
      break;
    default:
      cLoad = color;
  }
  let wLoad = w ? w : 50;
  let hLoad = h ? h : 50;
  let tLoad = type ? type : "spin";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0",
        width: "100%",
      }}
      className="Loader"
    >
      <ReactLoading color={cLoad} type={tLoad} width={wLoad} height={hLoad} />
    </div>
  );
};

export default Loading;
