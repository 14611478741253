import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { Link, useParams } from "react-router-dom";
import { listOrderDetails } from "../Redux/Actions/OrderActions";
import Loading from "../Components/Common/Loading";
import moment from "moment";

const TrackingDetailsScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const orderDetailState = useSelector((state) => state.orderDetails);
  const { loading, error, order } = orderDetailState;
  useEffect(() => {
    if (!order) {
      dispatch(listOrderDetails(id));
    } else if (order) {
      if (order._id != id) {
        dispatch(listOrderDetails(id));
      }
    }
  }, [order, dispatch, id]);

  return (
    <React.Fragment>
      <Navbar />
      <div className="ci-tracking-screen-container ci-first-section ci-container">
        <div className="ci-tracking-screen-heading">
          <h2>
            Tracking{" "}
            {order
              ? order.customer.fname
                ? `${order.customer.fname}'s Order`
                : ""
              : ""}
          </h2>
          {loading ? (
            <Loading color="orange" w="60px" h="60px" />
          ) : error ? (
            <React.Fragment>
              <h3>Order not Found</h3>
              <Link className="admin-header-backlink" to="/">
                Click to Back
              </Link>
            </React.Fragment>
          ) : (
            order && (
              <React.Fragment>
                <div className="mb-4 row">
                  <div className="col-12 admin-order-details-wrap">
                    <h4>Order Details</h4>
                    <div className="admin-order-details-table-items">
                      <div className="admin-order-details-row">
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Name</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Product Link</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Quantity</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Price</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Total</b>
                          </p>
                        </div>
                      </div>
                      {order.orderItems &&
                        order.orderItems.map((item, i) => (
                          <div key={i} className="admin-order-details-row">
                            <div className="admin-order-details-cell">
                              <p>{item.name}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <Link to={`/product/${item.product}`}>
                                View Item
                              </Link>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>{item.qty}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>${item.price}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>${item.price * item.qty}</p>
                            </div>
                          </div>
                        ))}
                      <div className="admin-order-details-row">
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Delivery</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>$35</b>
                          </p>
                        </div>
                      </div>
                      <div className="admin-order-details-row">
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Total</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>${order.totalPrice}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 className="ci-tracking-status">
                        Delivery Status:{" "}
                        <span
                          className={
                            order.deliveryStatus === "Preparing Order"
                              ? "ci-tracking-preparing"
                              : order.deliveryStatus === "Out for Delivery"
                              ? "ci-tracking-delivery"
                              : order.deliveryStatus ===
                                "Preparing In Person Order"
                              ? "ci-tracking-preparing"
                              : "ci-tracking-delivered"
                          }
                        >
                          {order.deliveryStatus === "Preparing Order"
                            ? "Preparing Order"
                            : order.deliveryStatus === "Out for Delivery"
                            ? "Out for Delivery"
                            : order.deliveryStatus ===
                              "Preparing In Person Order"
                            ? "Preparing In Person Order"
                            : "Delivered"}
                        </span>
                      </h4>
                      {order.isDelivered && (
                        <h4 className="ci-tracking-status">
                          Delivery Time:{" "}
                          <span className="ci-tracking-time">
                            {moment(order.deliveredAt).calendar()}
                          </span>
                        </h4>
                      )}
                    </div>
                    <div>
                      <h4 className="ci-tracking-status mt-5">
                        Order Status:{" "}
                        <span
                          className={
                            order.isPaid
                              ? "ci-tracking-delivered"
                              : "ci-tracking-undelivered"
                          }
                        >
                          {order.isPaid
                            ? `Order Successful`
                            : "Payment Unsuccessful. Sorry, please try again."}
                        </span>
                      </h4>
                      {order.isPaid && (
                        <h4 className="ci-tracking-status">
                          Order Time:{" "}
                          <span className="ci-tracking-time">
                            {moment(order.paidAt).calendar()}
                          </span>
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default TrackingDetailsScreen;
