import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_USER_INFO,
} from "../Constants/CartConstants";
import url from "../../URL";

//CART ADD ITEM
export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`${url}/api/products/${id}`);
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image: data.image,
      price: data.price,
      countInStock: data.countInStock,
      qty,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

//CART REMOVE ITEM
export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

//CART SAVE USER INFO
export const saveUserInfo = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_USER_INFO, payload: data });
  localStorage.setItem("userInfo", JSON.stringify(data));
};
