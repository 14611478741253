import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  listOrderDetails,
  updateOrderPay,
} from "../Redux/Actions/OrderActions";
import Loading from "../Components/Common/Loading";
import moment from "moment";

const CheckoutStatusScreen = () => {
  const { id } = useParams();
  const [card, setCard] = useState("");
  const [currency, setCurrency] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [data, setData] = useState("");
  const [date, setDate] = useState("");
  const [hash, setHash] = useState("");
  const [message, setMessage] = useState("");
  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState("");
  const [total, setTotal] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const location = useLocation();

  const dispatch = useDispatch();
  const orderDetailState = useSelector((state) => state.orderDetails);
  const { loading, error, order } = orderDetailState;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Extract the parameter values from the URL
    const cardParam = searchParams.get("card");
    const currencyParam = searchParams.get("currency");
    const customerAddressParam = searchParams.get("customer_address");
    const customerEmailParam = searchParams.get("customer_email");
    const customerNameParam = searchParams.get("customer_name");
    const dataParam = searchParams.get("data");
    const dateParam = searchParams.get("date");
    const hashParam = searchParams.get("hash");
    const messageParam = searchParams.get("message");
    const orderIdParam = searchParams.get("order_id");
    const statusParam = searchParams.get("status");
    const totalParam = searchParams.get("total");
    const transactionIdParam = searchParams.get("transaction_id");

    // Set the state with the parameter values
    setCard(cardParam || "");
    setCurrency(currencyParam || "");
    setCustomerAddress(customerAddressParam || "");
    setCustomerEmail(customerEmailParam || "");
    setCustomerName(customerNameParam || "");
    setData(dataParam || "");
    setDate(dateParam || "");
    setHash(hashParam || "");
    setMessage(messageParam || "");
    setOrderId(orderIdParam || "");
    setStatus(statusParam || "");
    setTotal(totalParam || "");
    setTransactionId(transactionIdParam || "");

    // Rest of your component code...
  }, [location]);

  useEffect(() => {
    if (!order) {
      dispatch(listOrderDetails(id));
    } else if (order) {
      if (order._id != id) {
        dispatch(listOrderDetails(id));
      }
    }
  }, [order, dispatch, id]);

  useEffect(() => {
    if (status && id) {
      if (status === "success") {
        dispatch(
          updateOrderPay({
            _id: id,
            customerName,
            customerEmail,
            date,
            total,
            transactionId,
            status,
            isPaid: true,
          })
        );
      } else if (status !== "success") {
        dispatch(
          updateOrderPay({
            _id: id,
            isPaid: false,
          })
        );
      }
    }
  }, [status, id]);

  return (
    <React.Fragment>
      <Navbar />
      <div className="ci-tracking-screen-container ci-first-section ci-container">
        <div className="ci-tracking-screen-heading">
          {loading ? (
            <Loading color="orange" w="60px" h="60px" />
          ) : error ? (
            <React.Fragment>
              <h3>Order not Found</h3>
              <Link className="admin-header-backlink" to="/">
                Click to Back
              </Link>
            </React.Fragment>
          ) : (
            order &&
            status && (
              <React.Fragment>
                <h2>
                  {order.customer.fname.toUpperCase()}{" "}
                  {order.customer.lname.toUpperCase()} - ORDER{" "}
                  {status.toUpperCase()}
                </h2>
                <div className="mb-4 row">
                  <div className="col-12 admin-order-details-wrap">
                    <h4>Order Details</h4>
                    <div className="admin-order-details-table-items">
                      <div className="admin-order-details-row">
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Name</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Product Link</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Quantity</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Price</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Total</b>
                          </p>
                        </div>
                      </div>
                      {order.orderItems &&
                        order.orderItems.map((item, i) => (
                          <div key={i} className="admin-order-details-row">
                            <div className="admin-order-details-cell">
                              <p>{item.name}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <Link to={`/product/${item.product}`}>
                                View Item
                              </Link>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>{item.qty}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>{item.price}</p>
                            </div>
                            <div className="admin-order-details-cell">
                              <p>{item.price * item.qty}</p>
                            </div>
                          </div>
                        ))}
                      <div className="admin-order-details-row">
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p></p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>Total (+$45 delivery)</b>
                          </p>
                        </div>
                        <div className="admin-order-details-cell">
                          <p>
                            <b>{order.totalPrice}</b>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h4 className="ci-tracking-status mt-2">
                        Payment Status:{" "}
                        <span
                          className={
                            order.isPaid
                              ? "ci-tracking-delivered"
                              : "ci-tracking-undelivered"
                          }
                        >
                          {order.isPaid
                            ? `Payment Successful`
                            : "Payment Unsuccessful. Sorry, please try again."}
                        </span>
                      </h4>
                      {order.isPaid && (
                        <h4 className="ci-tracking-status">
                          Payment Time:{" "}
                          <span className="ci-tracking-time">
                            {moment(order.PaidAt).calendar()}
                          </span>
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          )}
        </div>
        <div className="col-12 admin-order-details-wrap">
          <h3 className="ci-payment-header">Payment Information</h3>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Order ID:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{orderId}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Currency:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{currency}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Customer Address:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{customerAddress}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Customer Email:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{customerEmail}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Customer Name:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{customerName}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Date Purchased:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{date}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Status:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{status}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Total:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{total}</p>
            </div>
          </div>
          <div className="admin-order-details-row">
            <div className="admin-order-details-h-cell">
              <p>Transaction Id:</p>
            </div>
            <div className="admin-order-details-cell">
              <p>{transactionId}</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default CheckoutStatusScreen;
