import {
  USER_ID_FAIL,
  USER_ID_REQUEST,
  USER_ID_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../Constants/UserConstants";
import axios from "axios";
import { toast } from "react-toastify";
import url from "../../URL";
import { v4 as uuidv4 } from "uuid";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

// LOGIN
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${url}/api/users/login`,
      { username, password },
      config
    );

    if (data.isAdmin === false) {
      toast.error("You are not an Admin", ToastObjects);
      dispatch({
        type: USER_LOGIN_FAIL,
      });
    } else {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    }

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    toast.error(message, ToastObjects);
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }

    dispatch({
      type: USER_LOGIN_FAIL,
      payload: message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  window.location.reload();
};

// ID Assign
export const getId = () => (dispatch, getState) => {
  try {
    const {
      userIdInfo: { userId },
    } = getState();

    if (!userId) {
      dispatch({ type: USER_ID_REQUEST });
      const newId = "uid-" + uuidv4().substring(0, 12);
      dispatch({ type: USER_ID_SUCCESS, payload: { id: newId } });
      localStorage.setItem("userId", JSON.stringify({ id: newId }));
    } else {
      dispatch({ type: USER_ID_SUCCESS, payload: userId });
      localStorage.setItem("userId", JSON.stringify(userId));
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_ID_FAIL,
      payload: message,
    });
  }
};
