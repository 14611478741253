import React, { useEffect, useState } from "react";
import Toast from "../../Components/Toast";
import AdminHeader from "../../Components/admin/AdminHeader";
import AdminSidebar from "../../Components/admin/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  listControls,
  updateControl,
} from "../../Redux/Actions/ControlActions";
import { Link } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import UploadWidget from "../../Components/admin/UploadWidget";
import { CONTROL_UPDATE_RESET } from "../../Redux/Constants/ControlConstants";

const AdminControlsScreen = () => {
  const [hs, setHS] = useState({});
  const [ops, setOPS] = useState({});
  const [ms, setMS] = useState({});
  const [cs, setCS] = useState({});
  const [ps, setPS] = useState({});
  const [control, setControl] = useState({});

  const dispatch = useDispatch();

  const controlDetailState = useSelector((state) => state.controlList);
  const { loading, error, controls } = controlDetailState;

  const controlUpdate = useSelector((state) => state.controlUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = controlUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CONTROL_UPDATE_RESET });
      dispatch(listControls());
    } else {
      if (JSON.stringify(controls) === "[{}]") {
        dispatch(listControls());
      }
      if (
        JSON.stringify(controls) !== "[]" &&
        JSON.stringify(controls) !== "[{}]"
      ) {
        const temp = { ...controls[0] };
        setControl({ ...temp });
      }
    }
  }, [setControl, controls, dispatch, successUpdate]);

  useEffect(() => {
    if (JSON.stringify(control) !== "{}") {
      setHS({ ...control.homeSettings });
      setOPS({ ...control.ourProductSettings });
      setMS({ ...control.mediaSettings });
      setCS({ ...control.contactSettings });
      setPS({ ...control.paymentSettings });
    }
  }, [control]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateControl({
        _id: controls[0]._id,
        homeSettings: hs,
        ourProductSettings: ops,
        mediaSettings: ms,
        contactSettings: cs,
        paymentSettings: ps,
      })
    );
  };

  const handleCurrency = (e) => {
    let inputValue = e.target.value;
    const formattedValue = `${Number(inputValue).toLocaleString("en")}.00`;
    return formattedValue;
  };

  return (
    <div>
      <AdminHeader />
      <Toast />
      <div className="interface-container">
        <AdminSidebar />
        <div className="main-display">
          <div className="admin-main-container">
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : loadingUpdate ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : error ? (
              <React.Fragment>
                <h3>{error}</h3>
                <Link className="admin-header-backlink" to="/admin/">
                  Go to Home Screen
                </Link>
              </React.Fragment>
            ) : successUpdate ? (
              <React.Fragment>
                <h3>Product edited successfully</h3>
                <Link className="admin-header-backlink" to="/admin/">
                  Go to Home Screen
                </Link>
              </React.Fragment>
            ) : JSON.stringify(control) !== "{}" ? (
              <React.Fragment>
                <div className="mb-5 admin-top-initial-container">
                  <h2 className="admin-header admin-inline">Site Settings</h2>
                  <Link
                    style={{
                      textDecoration: "none !important",
                      color: "var(--lavender-dark)",
                    }}
                    className="admin-header-backlink"
                    to="/admin/"
                  >
                    <i
                      style={{ color: "var(--orange)" }}
                      className="fas fa-arrow-left"
                    ></i>{" "}
                    Go to Home
                  </Link>
                </div>
                <form onSubmit={submitHandler}>
                  <h3 className="admin-form-heading mb-3">Home Settings</h3>
                  {/*Home A*/}
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label
                        htmlFor="hs_bannerHeading_a"
                        className="form-label"
                      >
                        Banner Heading 1{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Banner Heading 1"
                        className="form-control"
                        id="hs_bannerHeading_a"
                        required
                        value={hs.bannerHeading_a}
                        onChange={(e) => {
                          const hstemp = {
                            ...hs,
                            bannerHeading_a: e.target.value,
                          };
                          setHS(hstemp);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label
                        htmlFor="hs_bannerSubHeading_a"
                        className="form-label"
                      >
                        Banner Sub Heading 1
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Banner Sub Heading 1"
                        className="form-control"
                        id="hs_bannerSubHeading_a"
                        required
                        value={hs.bannerSubHeading_a}
                        onChange={(e) => {
                          const hstemp = {
                            ...hs,
                            bannerSubHeading_a: e.target.value,
                          };
                          setHS(hstemp);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 row align-products-center">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_mainImage" className="form-label">
                        Banner Image 1{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>

                      <UploadWidget
                        onUpload={(url) => {
                          const hstemp = { ...hs, bannerImage_a: url };
                          setHS(hstemp);
                        }}
                        val={hs.bannerImage_a}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      {hs.bannerImage_a && (
                        <img
                          src={hs.bannerImage_a}
                          className="adminImagePreview"
                          alt="main_image"
                        />
                      )}
                    </div>
                  </div>
                  {/*Home B*/}
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label
                        htmlFor="hs_bannerHeading_b"
                        className="form-label"
                      >
                        Banner Heading 2{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Banner Heading 2"
                        className="form-control"
                        id="hs_bannerHeading_b"
                        required
                        value={hs.bannerHeading_b}
                        onChange={(e) => {
                          const hstemp = {
                            ...hs,
                            bannerHeading_b: e.target.value,
                          };
                          setHS(hstemp);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label
                        htmlFor="hs_bannerSubHeading_b"
                        className="form-label"
                      >
                        Banner Sub Heading 2
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Banner Sub Heading 2"
                        className="form-control"
                        id="hs_bannerSubHeading_b"
                        required
                        value={hs.bannerSubHeading_b}
                        onChange={(e) => {
                          const hstemp = {
                            ...hs,
                            bannerSubHeading_b: e.target.value,
                          };
                          setHS(hstemp);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 row align-products-center">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="product_mainImage" className="form-label">
                        Banner Image 2{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>

                      <UploadWidget
                        onUpload={(url) => {
                          const hstemp = { ...hs, bannerImage_b: url };
                          setHS(hstemp);
                        }}
                        val={hs.bannerImage_b}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      {hs.bannerImage_b && (
                        <img
                          src={hs.bannerImage_b}
                          className="adminImagePreview"
                          alt="main_image"
                        />
                      )}
                    </div>
                  </div>
                  {/*Home Buttons*/}
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="hs_bannerButton_a" className="form-label">
                        Banner Button 1{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Banner Button 1"
                        className="form-control"
                        id="hs_bannerButton_a"
                        required
                        value={hs.bannerButton_a}
                        onChange={(e) => {
                          const hstemp = {
                            ...hs,
                            bannerButton_a: e.target.value,
                          };
                          setHS(hstemp);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="hs_bannerButton_b" className="form-label">
                        Banner Button 2
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Banner Button 2"
                        className="form-control"
                        id="hs_bannerButton_b"
                        required
                        value={hs.bannerButton_b}
                        onChange={(e) => {
                          const hstemp = {
                            ...hs,
                            bannerButton_b: e.target.value,
                          };
                          setHS(hstemp);
                        }}
                      />
                    </div>
                  </div>
                  <h3 className="admin-form-heading mb-3">
                    Our Product Information Settings
                  </h3>
                  {/* Our Product Information Settings*/}
                  <div className="mb-4 row">
                    <div className="col-lg-12 p-2">
                      <label htmlFor="ops_description_a" className="form-label">
                        Description Paragraph 1{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <textarea
                        id="ops_description_a"
                        placeholder="Enter Description Paragraph 1"
                        className="form-control"
                        rows="6"
                        required
                        value={ops.description_a}
                        onChange={(e) => {
                          const opstemp = {
                            ...ops,
                            description_a: e.target.value,
                          };
                          setOPS(opstemp);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-lg-12 p-2">
                      <label htmlFor="ops_description_b" className="form-label">
                        Description Paragraph 2{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <textarea
                        id="ops_description_b"
                        placeholder="Enter Description Paragraph 2"
                        className="form-control"
                        rows="6"
                        required
                        value={ops.description_b}
                        onChange={(e) => {
                          const opstemp = {
                            ...ops,
                            description_b: e.target.value,
                          };
                          setOPS(opstemp);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <h3 className="admin-form-heading mb-3">Contact Settings</h3>
                  {/* Facebook Contact Settings */}
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="cs_phone" className="form-label">
                        Phone <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="tel"
                        placeholder="Enter Phone Number"
                        className="form-control"
                        id="cs_phone"
                        required
                        value={cs.phone}
                        onChange={(e) => {
                          const cstemp = {
                            ...cs,
                            phone: e.target.value,
                            call: e.target.value,
                          };
                          setCS(cstemp);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="cs_facebook" className="form-label">
                        Facebook
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="url"
                        placeholder="Enter Facebook Link"
                        className="form-control"
                        id="cs_facebook"
                        required
                        value={cs.facebook}
                        onChange={(e) => {
                          const cstemp = {
                            ...cs,
                            facebook: e.target.value,
                          };
                          setCS(cstemp);
                        }}
                      />
                    </div>
                  </div>
                  {/* Whatsapp Instagram Settings */}
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="cs_whatsapp" className="form-label">
                        Whatsapp <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="tel"
                        placeholder="Enter Whatsapp Number"
                        className="form-control"
                        id="cs_whatsapp"
                        required
                        value={cs.whatsapp}
                        onChange={(e) => {
                          const cstemp = {
                            ...cs,
                            whatsapp: e.target.value,
                          };
                          setCS(cstemp);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="cs_instagram" className="form-label">
                        Instagram
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="url"
                        placeholder="Enter Instagram Link"
                        className="form-control"
                        id="cs_instagram"
                        required
                        value={cs.instagram}
                        onChange={(e) => {
                          const cstemp = {
                            ...cs,
                            instagram: e.target.value,
                          };
                          setCS(cstemp);
                        }}
                      />
                    </div>
                  </div>
                  <h3 className="admin-form-heading mb-3">Payment Settings</h3>
                  {/* Payment Settings */}
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="ps_deliverycost" className="form-label">
                        Delivery Cost{" "}
                        <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Delivery Cost"
                        className="form-control"
                        id="ps_deliverycost"
                        required
                        value={ps.deliveryCost}
                        onChange={(e) => {
                          const pstemp = {
                            ...ps,
                            deliveryCost: e.target.value,
                          };
                          setPS(pstemp);
                        }}
                        onBlur={(e) => {
                          const pstemp = {
                            ...ps,
                            deliveryCost: handleCurrency(e),
                          };
                          setPS(pstemp);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100 center admin-prodbtn-container">
                    <button
                      type="submit"
                      className="ci-btn-full-light-blue admin-add-button"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminControlsScreen;
