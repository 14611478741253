//Home
export const homeSettings = {
  bannerImage_a: "/images/Model1.png",
  bannerHeading_a: "Welcome To",
  bannerSubHeading_a: "Berly Essentials",
  bannerButton_a: "Shop Now",
  bannerHeading_b: "10% OFF",
  bannerSubHeading_b: "On All Items",
  bannerButton_b: "Shop Now",
  bannerImage_b: "/images/Model2.png",
};

//Our Product
export const ourProductSettings = {
  description_a:
    "Welcome to Berly Essentials, the go-to destination for Caribbean women who want to keep their hair healthy and beautiful. Our star product, the Hair Moisturizer, is infused with natural ingredients like almond oil and coco butter to deliver deep hydration and nourishment, leaving your hair soft, silky, and radiant.",
  description_b:
    "We offer a range of moisturizers to suit different hair types, along with other beauty items like body scrubs and lip balms, all made with the same high-quality, cruelty-free, and environmentally-friendly ingredients. Treat yourself to the magic of Berly Essentials and experience the difference for yourself!",
};

//Media
export const mediaSettings = {
  speed: 500,
  photos: [
    "/images/Product1.jpg",
    "/images/Product2.jpg",
    "/images/Product3.jpg",
    "/images/Product4.jpg",
    "/images/Product5.jpg",
    "/images/Product6.jpg",
    "/images/Product7.jpg",
    "/images/Product8.jpg",
  ],
  videos: [
    "https://www.youtube.com/watch?v=v5_RuDizifE",
    "https://www.youtube.com/watch?v=2FmgCc9UvW8",
    "https://www.youtube.com/watch?v=7juX8D-yVIQ",
  ],
};

export const contactSettings = {
  phone: "+1-868-701-4388",
  facebook: "https://www.facebook.com/profile.php?id=100077325894300",
  whatsapp: "+1-868-701-4388",
  instagram: "https://www.instagram.com/caramelisle/",
  call: "+1-868-701-4388",
};

export const paymentSettings = {
  deliveryCost: 15.0,
};
