import React, { useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentSettings } from "../Data/pagecontrols";
import {
  createInPersonOrder,
  createOrder,
} from "./../Redux/Actions/OrderActions";
import { CART_CLEAR_ITEMS } from "../Redux/Constants/CartConstants";
import { ORDER_CREATE_RESET } from "../Redux/Constants/OrderConstants";
import Message from "./../Components/Common/Message";
import ErrorMessage from "./../Components/Common/Error";
import { v4 as uuidv4 } from "uuid";
import url from "../URL";
import axios from "axios";
import { getId } from "../Redux/Actions/UserActions";
import Toast from "../Components/Toast";
import { toast } from "react-toastify";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const OrderScreen = () => {
  const navigate = useNavigate();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");

  const [paymentControls, setPaymentControls] = useState({
    ...paymentSettings,
  });

  const controlList = useSelector((state) => state.controlList);
  const { controls } = controlList;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;
  const dispatch = useDispatch();

  const cartItemStore = useSelector((state) => state.cart);
  const { cartItems: cart } = cartItemStore;

  const totalCost = cart
    ? cart.reduce((acc, item) => acc + item.price * item.qty, 0) +
      paymentControls.deliveryCost
    : 0.0;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    if (controls && controls.length >= 1) {
      setPaymentControls({ ...controls[0].paymentSettings });
    }
  }, [controls]);

  const handlePayment = async (e) => {
    if (
      !validateName(fname) ||
      !validateName(lname) ||
      !validateCity(city) ||
      !validateStreet(street) ||
      !validatePhone(phone) ||
      !validateEmail(email)
    ) {
      e.preventDefault();
      toast.error(
        "Please ensure the highlighted fields are valid",
        ToastObjects
      );
    } else {
      e.preventDefault();
      const oid = "oid-" + uuidv4().substring(0, 12);
      let userId = JSON.parse(window.localStorage.getItem("userId"));
      if (!userId) {
        dispatch(getId());
        userId = JSON.parse(window.localStorage.getItem("userId"));
      }
      cart &&
        totalCost > 0 &&
        dispatch(
          createOrder(
            {
              id: userId.id,
              fname,
              lname,
              email,
              phone,
              city,
              street,
              orderItems: cart,
              totalPrice: totalCost,
            },
            oid,
            totalCost
          )
        );
    }
  };
  const handleInPersonPayment = async (e) => {
    e.preventDefault();
    if (
      !validateName(fname) ||
      !validateName(lname) ||
      !validateCity(city) ||
      !validateStreet(street) ||
      !validatePhone(phone) ||
      !validateEmail(email)
    ) {
      e.preventDefault();
      toast.error(
        "Please ensure the highlighted fields are valid",
        ToastObjects
      );
    } else {
      const oid = "oid-" + uuidv4().substring(0, 12);
      let userId = JSON.parse(window.localStorage.getItem("userId"));
      if (!userId) {
        dispatch(getId());
        userId = JSON.parse(window.localStorage.getItem("userId"));
      }
      console.log("inside here");

      cart &&
        totalCost > 0 &&
        dispatch(
          createInPersonOrder(
            {
              id: userId.id,
              fname,
              lname,
              email,
              phone,
              city,
              street,
              orderItems: cart,
              totalPrice: totalCost,
            },
            oid,
            totalCost
          )
        );
    }
  };
  //Contact formatting stuff
  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const digitsOnly = inputValue.replace(/[^\d]/g, "");

    if (digitsOnly.length <= 10) {
      let formattedPhone = "";

      if (digitsOnly.length >= 4) {
        formattedPhone += `(${digitsOnly.slice(0, 3)})`;
      } else if (digitsOnly.length > 0) {
        formattedPhone += `(${digitsOnly}`;
      }

      if (digitsOnly.length >= 7) {
        formattedPhone += `${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
      } else if (digitsOnly.length > 3) {
        formattedPhone += `${digitsOnly.slice(3)}`;
      }

      setPhone(formattedPhone);
    }
  };
  //Validation Logic
  const validateName = (name) => {
    return /^[a-zA-Z\s'".-]*$/.test(name);
  };
  const validateCity = (city) => {
    return /^[a-zA-Z\s'".-]*$/.test(city) && city.length <= 100;
  };
  const validatePhone = (phone) => {
    return phone.length > 12;
  };
  const validateStreet = (street) => {
    return /^[a-zA-Z0-9\s'".-]*$/.test(street) && street.length <= 100;
  };
  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const handleBlur = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    switch (inputName) {
      case "fname":
        if (!validateName(inputValue)) {
          e.target.classList.add("field-error");
          toast.error("Please ensure first name is valid.", ToastObjects);
        } else {
          e.target.classList.remove("field-error");
        }
        break;
      case "lname":
        if (!validateName(inputValue)) {
          e.target.classList.add("field-error");
          toast.error("Please ensure last name is valid.", ToastObjects);
        } else {
          e.target.classList.remove("field-error");
        }
        break;
      case "email":
        if (!validateEmail(inputValue)) {
          e.target.classList.add("field-error");
          toast.error("Please ensure email is valid.", ToastObjects);
        } else {
          e.target.classList.remove("field-error");
        }
        break;
      case "contact":
        if (!validatePhone(inputValue)) {
          e.target.classList.add("field-error");
          toast.error("Please ensure contact is valid.", ToastObjects);
        } else {
          e.target.classList.remove("field-error");
        }
        break;
      case "city":
        if (!validateCity(inputValue)) {
          e.target.classList.add("field-error");
          toast.error("Please ensure city is valid.", ToastObjects);
        } else {
          e.target.classList.remove("field-error");
        }
        break;
      case "street":
        if (!validateStreet(inputValue)) {
          e.target.classList.add("field-error");
          toast.error("Please ensure street is valid.", ToastObjects);
        } else {
          e.target.classList.remove("field-error");
        }
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (success) dispatch({ type: ORDER_CREATE_RESET });
  // }, [success]);

  return (
    <React.Fragment>
      <Navbar />
      <Toast />
      <div className="row ci-order-screen-container ci-first-section ci-container">
        <h2 className="ci-order-screen-heading">Checkout</h2>
        <div className="ci-order-summary-container">
          <div>
            <Link
              style={{
                fontSize: "large",
                display: "inline-block",
                margin: "1px 0 18px",
              }}
              to="/cart/"
            >
              <i
                style={{ paddingRight: "5px" }}
                className="fas fa-arrow-alt-square-left"
              ></i>{" "}
              Back to Cart
            </Link>
          </div>
          {cart.length > 0 && (
            <React.Fragment>
              <h3>Summary</h3>
              <table>
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Item Cost</th>
                    <th>Item Quantity</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {cart &&
                    cart.length > 0 &&
                    cart.map((item, i) => (
                      <tr
                        style={
                          i % 2 === 0 ? { background: "var(--light-blue)" } : {}
                        }
                        key={i}
                        className="ci-order-screen-item-container"
                      >
                        <td>{item.name}</td>
                        <td>${item.price}</td>
                        <td>{item.qty}</td>
                        <td>${item.price * item.qty}</td>
                      </tr>
                    ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "end" }}>Delivery:</td>
                    <td>${paymentControls.deliveryCost}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <th style={{ textAlign: "end" }}>Total:</th>
                    <td>
                      <b>${totalCost}</b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </React.Fragment>
          )}
        </div>
        {success ? (
          <Message
            title="Order Submitted Successfully"
            message={`Order Details: ID: ${order._id} Name: ${order.customer.fname} ${order.customer.lname} Location: ${order.customer.street}, ${order.customer.city}`}
          />
        ) : (
          <div className="ci-order-payment-form-container">
            {error && <ErrorMessage message={error} />}
            {cart.length > 0 && (
              <div className="ci-order-customer-information">
                <h3>Customer Details</h3>
                <p className="ci-order-form-instructions">
                  Fill out all fields to check out your items.
                </p>
                <form
                  action="submit"
                  onSubmit={(e) => {
                    if (
                      !validateName(fname) ||
                      !validateName(lname) ||
                      !validateCity(city) ||
                      !validateStreet(street) ||
                      !validatePhone(phone) ||
                      !validateEmail(email)
                    ) {
                      e.preventDefault();
                      toast.error(
                        "Please ensure the highlighted fields are valid",
                        ToastObjects
                      );
                    } else handlePayment(e);
                  }}
                >
                  <label className="ci-order-form-title" htmlFor="fname">
                    First Name
                  </label>
                  <input
                    className="ci-order-form-input"
                    type="text"
                    name="fname"
                    value={fname}
                    pattern="[\p{L}\s\-']+$"
                    onChange={(e) => setFname(e.target.value)}
                    id="fname"
                    maxLength="100"
                    placeholder="Enter first name."
                    onBlur={handleBlur}
                    required
                  />
                  <label className="ci-order-form-title" htmlFor="lname">
                    Last Name
                  </label>
                  <input
                    className="ci-order-form-input"
                    type="text"
                    name="lname"
                    pattern="[\p{L}\s\-']+$"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    id="lname"
                    maxLength="100"
                    placeholder="Enter last name."
                    onBlur={handleBlur}
                    required
                  />
                  <label className="ci-order-form-title" htmlFor="contact">
                    Contact Number
                  </label>
                  <input
                    className="ci-order-form-input"
                    type="tel"
                    name="contact"
                    value={phone}
                    placeholder="(868)___-____"
                    minLength="13"
                    onBlur={handleBlur}
                    onChange={handlePhoneChange}
                    id="contact"
                    required
                  />
                  <label className="ci-order-form-title" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="ci-order-form-input"
                    type="email"
                    name="email"
                    value={email}
                    maxLength="100"
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    placeholder="Enter email."
                    onBlur={handleBlur}
                    required
                  />
                  <label className="ci-order-form-title" htmlFor="city">
                    City/Area
                  </label>
                  <input
                    placeholder="Enter city/area."
                    type="text"
                    value={city}
                    pattern="^[\p{L}\s\d\-']+$"
                    onChange={(e) => setCity(e.target.value)}
                    className="ci-order-form-input"
                    onBlur={handleBlur}
                    name="city"
                    maxLength="100"
                    id="city"
                    required
                  />
                  <label className="ci-order-form-title" htmlFor="street">
                    Street
                  </label>
                  <input
                    placeholder="Enter street."
                    type="text"
                    pattern="^[\p{L}\s\d\-']+$"
                    className="ci-order-form-input"
                    name="street"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    onBlur={handleBlur}
                    id="street"
                    maxLength="100"
                    required
                  />
                  <div className="ci-order-form-btn-container">
                    <button
                      className="ci-order-form-btn ci-order-form-btn-paypal"
                      onClick={handleInPersonPayment}
                    >
                      Checkout and Pay in Person
                    </button>
                  </div>
                  <div className="ci-order-form-btn-container">
                    <button
                      type="submit"
                      className="ci-order-form-btn ci-order-form-btn-visa"
                    >
                      Checkout with Visa Credit Card
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default OrderScreen;
