import React, { useEffect, useState } from "react";
import Toast from "../../Components/Toast";
import AdminHeader from "../../Components/admin/AdminHeader";
import AdminSidebar from "../../Components/admin/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  listOrderDetails,
  updateOrder,
  deleteOrder,
} from "../../Redux/Actions/OrderActions";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import { ORDER_UPDATE_RESET } from "../../Redux/Constants/OrderConstants";

const AdminOrderDetailsScreen = () => {
  const { id } = useParams();
  const [deliveryStatus, setDeliveryStatus] = useState("Preparing Order");

  const dispatch = useDispatch();

  const orderDetailState = useSelector((state) => state.orderDetails);
  const { loading, error, order } = orderDetailState;
  const orderUpdate = useSelector((state) => state.orderUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = orderUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ORDER_UPDATE_RESET });
    } else {
      if (!order) {
        dispatch(listOrderDetails(id));
      } else {
        setDeliveryStatus(order.deliveryStatus);
      }
    }
    if (order) {
      if (order._id != id) {
        dispatch(listOrderDetails(id));
      }
    }
  }, [order, dispatch, id, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    let isDelivered = deliveryStatus === "Delivered" ? true : false;
    dispatch(
      updateOrder({
        _id: id,
        isDelivered,
        deliveryStatus,
      })
    );
  };

  const deletehandler = () => {
    if (order) {
      if (
        window.confirm(
          `Are you sure you want to delete ${order.customer.fname}'s Order?`
        )
      ) {
        dispatch(deleteOrder(id));
      }
    }
  };
  return (
    <div>
      <AdminHeader />
      <Toast />
      <div className="interface-container">
        <AdminSidebar />
        <div className="main-display">
          <div className="admin-main-container">
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : loadingUpdate ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : error ? (
              <React.Fragment>
                <h3>{error}</h3>
                <Link className="admin-header-backlink" to="/admin/orders/">
                  Go to Orders Screen
                </Link>
              </React.Fragment>
            ) : errorUpdate ? (
              <React.Fragment>
                <h3>{errorUpdate}</h3>
                <Link className="admin-header-backlink" to="/admin/orders/">
                  Go to Orders Screen
                </Link>
              </React.Fragment>
            ) : successUpdate ? (
              <React.Fragment>
                <h3>Order edited successfully</h3>
                <Link className="admin-header-backlink" to="/admin/orders/">
                  Go to Orders Screen
                </Link>
              </React.Fragment>
            ) : order ? (
              !order.isInPerson && (
                <React.Fragment>
                  <div className="mb-5 admin-top-initial-container">
                    <h2 className="admin-header admin-inline">
                      {order.customer.fname} {order.customer.lname}'s Order
                    </h2>
                    <Link
                      style={{
                        textDecoration: "none !important",
                        color: "var(--lavender-dark)",
                      }}
                      className="admin-header-backlink"
                      to="/admin/orders"
                    >
                      <i
                        style={{ color: "var(--orange)" }}
                        className="fas fa-arrow-left"
                      ></i>{" "}
                      Go to Orders
                    </Link>
                  </div>
                  <div className="admin-order-delete-button-container">
                    <i
                      className="fas fa-trash admin-order-delete-button"
                      onClick={deletehandler}
                    ></i>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-12 admin-order-details-wrap">
                      <h4>Customer Details</h4>
                      <div className="admin-order-details-table">
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-h-cell">
                            <p>User Session Id</p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>{order.customer.id}</p>
                          </div>
                        </div>
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-h-cell">
                            <p>Payment Status</p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              {order.isPaid
                                ? "Paid"
                                : "Payment Cancelled/Incomplete"}
                            </p>
                          </div>
                        </div>
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-h-cell">
                            <p>Full Name</p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              {order.customer.fname} {order.customer.lname}
                            </p>
                          </div>
                        </div>
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-h-cell">
                            <p>Email</p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>{order.customer.email}</p>
                          </div>
                        </div>
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-h-cell">
                            <p>Phone</p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>{order.customer.phone}</p>
                          </div>
                        </div>
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-h-cell">
                            <p>City</p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>{order.customer.city}</p>
                          </div>
                        </div>
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-h-cell">
                            <p>Street</p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>{order.customer.street}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-12 admin-order-details-wrap">
                      <h4>Order Details</h4>
                      <div className="admin-order-details-table-items">
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-cell">
                            <p>
                              <b>Name</b>
                            </p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              <b>Product Link</b>
                            </p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              <b>Quantity</b>
                            </p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              <b>Price</b>
                            </p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              <b>Total</b>
                            </p>
                          </div>
                        </div>
                        {order.orderItems &&
                          order.orderItems.map((item, i) => (
                            <div key={i} className="admin-order-details-row">
                              <div className="admin-order-details-cell">
                                <p>{item.name}</p>
                              </div>
                              <div className="admin-order-details-cell">
                                <Link to={`/product/${item.product}`}>
                                  View Item
                                </Link>
                              </div>
                              <div className="admin-order-details-cell">
                                <p>{item.qty}</p>
                              </div>
                              <div className="admin-order-details-cell">
                                <p>{item.price}</p>
                              </div>
                              <div className="admin-order-details-cell">
                                <p>{item.price * item.qty}</p>
                              </div>
                            </div>
                          ))}
                        <div className="admin-order-details-row">
                          <div className="admin-order-details-cell">
                            <p></p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p></p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p></p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              <b>Total</b>
                            </p>
                          </div>
                          <div className="admin-order-details-cell">
                            <p>
                              <b>{order.totalPrice}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-lg-6 col-sm-12 p-2">
                      <label htmlFor="order_isDelivered" className="form-label">
                        Delivered? <span style={{ color: "hotpink" }}>*</span>
                      </label>
                      <select
                        className="form-select"
                        id="order_isDelivered"
                        required
                        value={deliveryStatus}
                        onChange={(e) => setDeliveryStatus(e.target.value)}
                      >
                        <option value={"Preparing Order"}>
                          Preparing Order
                        </option>
                        <option value={"Out for Delivery"}>
                          Out for Delivery
                        </option>
                        <option value={"Delivered"}>Delivered</option>
                      </select>
                    </div>
                  </div>
                  <form onSubmit={submitHandler}>
                    <div className="w-100 center admin-prodbtn-container">
                      <button
                        type="submit"
                        className="ci-btn-full-light-blue admin-add-button"
                      >
                        Update Order
                      </button>
                    </div>
                  </form>
                </React.Fragment>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrderDetailsScreen;
