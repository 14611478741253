import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { mediaSettings } from "../Data/pagecontrols";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { Fade, Slide, Bounce } from "react-awesome-reveal";
import { listControls } from "../Redux/Actions/ControlActions";
import Loading from "../Components/Common/Loading";
import ErrorMessage from "../Components/Common/Error";
import { Helmet } from "react-helmet";

const MediaScreen = () => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [imgLoad, setImgLoad] = useState(true);

  const [mediaControls, setMediaControls] = useState({
    photos: [],
    videos: [],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    dispatch(listControls());
  }, []);

  const controlList = useSelector((state) => state.controlList);
  const { loading, error, controls } = controlList;

  useEffect(() => {
    if (
      controls &&
      JSON.stringify(controls) !== "[]" &&
      JSON.stringify(controls) !== "[{}]"
    ) {
      const mediaControlsTemp = controls[0]
        ? { ...controls[0].mediaSettings }
        : { photos: [], videos: [] };
      setMediaControls({ ...mediaControlsTemp });
    } else {
      setMediaControls({ photos: [], videos: [] });
    }
  }, [controls]);

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + "mediaGallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    let lightboxVid = new PhotoSwipeLightbox({
      gallery: "#" + "mediaGalleryVid",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightboxVid.on("contentLoad", (e) => {
      const { content } = e;
      e.preventDefault();
      if (content.type === "youtube_embed") {
        content.element = document.createElement("div");
        content.element.className = "media-youtube-container";
        const iframe = document.createElement("iframe");
        iframe.setAttribute("allowfullscreen", "");
        iframe.width = "100%";
        iframe.height = "100%";
        iframe.src = content.data.src;
        content.element.appendChild(iframe);
      }
    });
    lightboxVid.init();
    lightbox.init();
    return () => {
      lightbox.destroy();
      lightbox = null;
      lightboxVid.destroy();
      lightboxVid = null;
    };
  }, []);
  useEffect(() => {
    if (mediaControls.photos.length > 0) {
      const newItems = mediaControls.photos.map((photo, i) => {
        const image = new Image();
        image.src = photo;
        return new Promise((resolve) => {
          image.onload = () => {
            resolve({
              src: photo,
              w: image.width,
              h: image.height,
              title: `Image ${i + 1}`,
            });
          };
        });
      });
      Promise.all(newItems).then((result) => {
        setImgLoad(false);
        setItems(result);
      });
    } else {
      setItems([]);
    }
  }, [mediaControls.photos]);

  useEffect(() => {
    if (items.length == 0) {
      setImgLoad(true);
    }
  }, [items]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Media</title>
        <meta
          name="description"
          content="View our eye-catching photos and videos!"
        />
      </Helmet>
      <Navbar />
      <div className="ci-media-screen-container ci-first-section ci-container">
        <h2 className="ci-media-screen-heading ci-first-heading">Images</h2>

        {
          <div className="pswp-gallery" id="mediaGallery">
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : imgLoad ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : (
              items &&
              items.map((item, i) => (
                <a
                  href={item.src}
                  data-pswp-width={item.w}
                  data-pswp-height={item.h}
                  key={i}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={item.src} alt={item.title} />
                </a>
              ))
            )}
          </div>
        }
        <div className="ci-media-screen-photos-container"></div>
        <div className="ci-media-screen-heading">
          <h2>Videos</h2>
        </div>
        <div className="ci-media-screen-videos-container">
          <div className="pswp-gallery" id="mediaGalleryVid">
            {loading ? (
              <Loading color="orange" w="60px" h="60px" />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              mediaControls.videos &&
              mediaControls.videos.map((video, i) => (
                <a
                  href={`https://www.youtube.com/embed/${video.split("v=")[1]}`}
                  key={i}
                  rel="noreferrer"
                  data-pswp-type="youtube_embed"
                  data-pswp-width={1280}
                  data-pswp-height={720}
                >
                  <img
                    src={`https://i.ytimg.com/vi/${
                      video.split("v=")[1]
                    }/mqdefault.jpg`}
                    alt={`video ${i}`}
                  />
                </a>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MediaScreen;
