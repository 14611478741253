import React from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { Link } from "react-router-dom";

const NotFoundScreen = () => {
  return (
    <React.Fragment>
      <Navbar />
      <div className="ci-not-found-screen-container ci-first-section ci-container">
        <div className="ci-not-found-screen-card">
          <h2 className="ci-not-found-screen-heading">Page Not Found.</h2>
          <i class="fad fa-page-break not-found-icon"></i>
          <Link to="/">Click Here to go Back to Home.</Link>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default NotFoundScreen;
