import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Actions/UserActions";

const AdminHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const mobileMenuOpenRef = useRef(mobileMenuOpen);
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    mobileMenuOpenRef.current = mobileMenuOpen;
  }, [mobileMenuOpen]);

  const handleCloseMenu = (e) => {
    if (
      !e.target.closest(".ci-nav-items-mobile-menu") &&
      mobileMenuOpenRef.current
    ) {
      setMobileMenuOpen(false);
    }
  };

  const handleClickMenu = (e) => {
    setMobileMenuOpen(!mobileMenuOpen);
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseMenu);

    return () => {
      document.removeEventListener("click", handleCloseMenu);
    };
  }, []);

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/admin/login");
  };

  return (
    <div className={`ci-nav-container ci-container ci-admin-nav`}>
      <div className="row ci-nav-logo-container">
        <div className="col-3 ci-nav-icon">
          <Link
            to="#"
            className={`ci-nav-search-container-mobile ci-nav-icon-container mobile-show ${
              mobileMenuOpen ? "mobile-menu-clicked" : ""
            }`}
            onClick={handleClickMenu}
          >
            <i className="fa fa-bars"></i>
          </Link>
        </div>
        <div className="col-6 ci-nav-logo">
          <img
            onClick={(e) => {
              navigate("/");
            }}
            className={`ci-nav-logo-svg `}
            src="/images/svgs/Logo.svg"
            alt=""
          />
        </div>
      </div>
      <div className="ci-nav-items-container mobile-hidden">
        {userInfo && (
          <nav className="ci-nav-items">
            <ul>
              <li className="ci-nav-item">
                <a
                  href="#"
                  onClick={logoutHandler}
                  className="ci-nav-mobile-menu-item-link ci-nav-admin-link"
                >
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
      <div
        className={`${
          mobileMenuOpen ? "mobile-menu-open" : "mobile-menu-close"
        } ci-nav-items-mobile-menu mobile-show`}
      >
        {userInfo && (
          <nav className="ci-nav-mobile-menu-items">
            <ul>
              <li className="ci-nav-mobile-menu-item">
                <a
                  href="#"
                  onClick={logoutHandler}
                  className="ci-nav-mobile-menu-item-link ci-logout"
                >
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default AdminHeader;
