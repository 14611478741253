import axios from "axios";
import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_PAY_UPDATE_FAIL,
  ORDER_PAY_UPDATE_REQUEST,
  ORDER_PAY_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_USER_LIST_FAIL,
  ORDER_USER_LIST_REQUEST,
  ORDER_USER_LIST_SUCCESS,
} from "../Constants/OrderConstants";
import { CART_CLEAR_ITEMS } from "../Constants/CartConstants";
import url from "../../URL";
import { toast } from "react-toastify";
import { getId, logout } from "./UserActions";
import {
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_UPDATE_STOCK_FAIL,
  PRODUCT_UPDATE_STOCK_REQUEST,
  PRODUCT_UPDATE_STOCK_SUCCESS,
} from "../Constants/ProductConstants";
import { useNavigate } from "react-router-dom";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

//CREATE ORDER
export const createOrder =
  (order, oid, totalCost) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_CREATE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(`${url}/api/orders`, order, config);

      dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });

      const dataPay = {
        orderid: oid,
        responseurl: `${process.env.REACT_APP_SITE_URL}/checkout/${data._id}`,
        total: totalCost,
      };

      const response = await axios.post(`${url}/api/orders/pay`, dataPay, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      window.location.href = response.data.url;

      //localStorage.removeItem("cartItems");
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload: message,
      });
    }
  };

//CREATE IN PERSON ORDER
export const createInPersonOrder =
  (order, oid, totalCost) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_CREATE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${url}/api/orders/inperson`,
        order,
        config
      );

      dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });

      //send email
      const success = await axios.post(`${url}/api/mail/notifyCreateOrder/`, {
        customerName: `${data.customer.fname + " " + data.customer.lname}`,
        email: data.customer.email,
        id: data._id,
        total: data.totalPrice,
      });

      if (success)
        window.location.href = `${
          process.env.REACT_APP_SITE_URL
        }/checkoutInPerson/${
          data._id
        }?oid=${oid}&totalCost=${totalCost}&customer_email=${
          data.customer.email
        }&customer_name=${
          data.customer.fname + " " + data.customer.lname
        }&customer_address=${
          data.customer.street + " " + data.customer.city
        }&order_id=${oid}&status=${"success"}`;

      //localStorage.removeItem("cartItems");
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload: message,
      });
    }
  };

//GET ALL ORDERS
export const listOrder = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_LIST_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${url}/api/orders`, config);
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//GET ALL ORDERS
export const listUserOrder = () => async (dispatch) => {
  try {
    dispatch({ type: ORDER_USER_LIST_REQUEST });
    let userId = JSON.parse(window.localStorage.getItem("userId"));
    if (!userId) {
      dispatch(getId());
      userId = JSON.parse(window.localStorage.getItem("userId"));
    }

    const { data } = await axios.get(`${url}/api/orders/tracking/${userId.id}`);
    dispatch({
      type: ORDER_USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//GET ORDER BY ID
export const listOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });
    const { data } = await axios.get(`${url}/api/orders/${id}`);
    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//UPDATE ORDER
export const updateOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${url}/api/orders/${order._id}`,
      order,
      config
    );

    dispatch({ type: ORDER_UPDATE_SUCCESS, payload: data });
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
    toast.success("Order Updated Successfully", ToastObjects);
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }

    dispatch({
      type: ORDER_UPDATE_FAIL,
      payload: message,
    });
    toast.error(message, ToastObjects);
  }
};

//UPDATE ORDER PAY
export const updateOrderPay = (order) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_PAY_UPDATE_REQUEST });
    const { data } = await axios.put(
      `${url}/api/orders/payment/${order._id}`,
      order
    );

    dispatch({ type: ORDER_PAY_UPDATE_SUCCESS, payload: data });
    dispatch({ type: CART_CLEAR_ITEMS });

    if (order.status === "success") {
      try {
        dispatch({ type: PRODUCT_UPDATE_STOCK_REQUEST });
        //updateProducts
        const { dataIn } = await axios.put(
          `${url}/api/products/stock/${order._id}`
        );
        dispatch({ type: PRODUCT_UPDATE_STOCK_SUCCESS, payload: dataIn });
        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: dataIn });
      } catch (err) {
        const messageIn =
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message;
        dispatch({
          type: PRODUCT_UPDATE_STOCK_FAIL,
          payload: messageIn,
        });
      }
      //send email
      axios.post(`${url}/api/mail/notify/`, {
        customerName: order.customerName,
        email: order.customerEmail,
        id: order._id,
        date: order.date,
        total: order.total,
        transactionId: order.transactionId,
      });
    }
    dispatch(listOrderDetails(order._id));
    localStorage.removeItem("cartItems");
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ORDER_PAY_UPDATE_FAIL,
      payload: message,
    });
  }
};

//UPDATE ORDER PAY IN PERSON
export const updateOrderPayInPerson = (order) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_PAY_UPDATE_REQUEST });
    const { data } = await axios.put(
      `${url}/api/orders/paymentInPerson/${order._id}`,
      order
    );

    dispatch({ type: ORDER_PAY_UPDATE_SUCCESS, payload: data });
    dispatch({ type: CART_CLEAR_ITEMS });

    if (order) {
      try {
        dispatch({ type: PRODUCT_UPDATE_STOCK_REQUEST });
        //updateProducts
        const { dataIn } = await axios.put(
          `${url}/api/products/stock/${order._id}`
        );
        dispatch({ type: PRODUCT_UPDATE_STOCK_SUCCESS, payload: dataIn });
        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: dataIn });
      } catch (err) {
        const messageIn =
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message;
        dispatch({
          type: PRODUCT_UPDATE_STOCK_FAIL,
          payload: messageIn,
        });
      }
      //send email
      axios.post(`${url}/api/mail/notify2/`, {
        customerName: order.customerName,
        email: order.customerEmail,
        id: order._id,
        date: order.date,
        total: order.total,
      });
    }
    dispatch(listOrderDetails(order._id));
    localStorage.removeItem("cartItems");
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ORDER_PAY_UPDATE_FAIL,
      payload: message,
    });
  }
};

//DELETE ORDER BY ID
export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DELETE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${url}/api/orders/${id}`, config);
    dispatch({ type: ORDER_DETAILS_SUCCESS });
    dispatch(listOrder());
    toast.success("Order Deleted Successfully", ToastObjects);
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    if (
      message === "Not authorized, no token" ||
      message === "Not authorized, token invalid"
    ) {
      dispatch(logout());
    }

    dispatch({
      type: ORDER_DELETE_FAIL,
      payload: message,
    });
    toast.error(message, ToastObjects);
  }
};
