import React, { useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../Components/Common/Message";
import { removeFromCart } from "../Redux/Actions/CartActions";
import { Helmet } from "react-helmet";

const CartScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  const handleCheckout = () => {
    navigate("/checkout");
  };
  const handleRemoveFromCart = (id) => {
    dispatch(removeFromCart(id));
  };
  const cartItemStore = useSelector((state) => state.cart);
  const { cartItems: cart } = cartItemStore;

  return (
    <React.Fragment>
      <Helmet>
        <title>Cart</title>
        <meta name="description" content="Your Cart" />
      </Helmet>
      <Navbar />
      <div className="row ci-cart-screen-container ci-first-section ci-container">
        <h2 className="ci-cart-screen-heading">Cart</h2>
        <div className="ci-cart-screen-container">
          {cart && cart.length === 0 ? (
            <Message
              title="Your Cart is Empty"
              message={
                <React.Fragment>
                  "Add products to your cart for purchase."{" "}
                  <i
                    style={{
                      display: "block",
                      fontSize: "x-large",
                      textAlign: "center",
                      padding: "20px 0",
                    }}
                    className="fas fa-cart-arrow-down"
                  ></i>
                  <Link to="/products/">
                    <i className="fas fa-arrow-left"></i> Go to Products
                  </Link>
                </React.Fragment>
              }
            />
          ) : (
            cart.map((item, i) => (
              <div key={i} className="ci-cart-screen-item-container row">
                <div className="col-3 ci-cart-item-cell">
                  <img
                    className="ci-cart-screen-item-image"
                    src={item.image}
                    alt={item.image}
                  />
                </div>
                <div className="col-4 ci-cart-item-cell">
                  <p className="ci-cart-screen-item-name">{item.name}</p>
                  <p className="ci-cart-screen-item-name">
                    <b>${item.price}</b> X <b>{item.qty}</b> =
                    <b> ${item.price * item.qty}</b>
                  </p>
                </div>
                <div className="col-5 ci-cart-item-cell">
                  <button
                    onClick={(e) => {
                      handleRemoveFromCart(item.product);
                    }}
                    className="ci-cart-screen-item-remove-btn"
                  >
                    Remove
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/product/${item.product}`);
                    }}
                    className="ci-cart-screen-item-view-item-btn"
                  >
                    View Item
                  </button>
                </div>
              </div>
            ))
          )}
          {cart && cart.length > 0 && (
            <div className="ci-cart-screen-total-cost-container row">
              <div className="col-3"> </div>
              <div className="col-4 ci-cart-item-cell">
                <p className="ci-cart-screen-total-cost">
                  Total = $
                  {cart.reduce((acc, item) => acc + item.price * item.qty, 0)}
                </p>
              </div>
              <div className="col-5 ci-cart-item-cell">
                <button
                  onClick={handleCheckout}
                  className="ci-cart-screen-checkout-btn"
                >
                  Checkout
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Link
                  style={{
                    fontSize: "larger",
                    display: "inline",
                    textAlign: "center",
                    marginTop: "80px",
                  }}
                  to="/products/"
                >
                  <i
                    style={{ paddingRight: "5px" }}
                    className="fas fa-arrow-alt-square-left"
                  ></i>{" "}
                  Continue Shopping
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CartScreen;
