import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { listOrder } from "../../Redux/Actions/OrderActions";
import { useDispatch, useSelector } from "react-redux";

const AdminSidebar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [displayOrders, setDisplayOrders] = useState([]);
  const dispatch = useDispatch();
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;
  const [unproc, setUnproc] = useState("");

  useEffect(() => {
    dispatch(listOrder());
  }, [dispatch]);

  useEffect(() => {
    if (orders) {
      let temp = String(
        orders.filter(
          (order) =>
            String(order.isDelivered).toLocaleLowerCase().includes("false") &&
            String(order.isPaid).toLocaleLowerCase().includes("true")
        ).length
      );
      orders ? setUnproc(temp == "0" ? "" : temp) : setUnproc("");
    } else {
      setUnproc("");
    }
  }, [orders]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className="sidebar-out-container">
      <div>
        <button
          className="jewel-admin-side-btn btn btn-icon btn-aside-show"
          onClick={toggleMenu}
        >
          <i className="aside-show-icon fas fa-stream"></i>
          <p className="aside-btn-menu-text">Menu</p>
        </button>
        <aside
          className={`navbar-aside ${showMenu ? "show" : "hide"}`}
          id="offcanvas_aside"
        >
          <div className="sidebar-container">
            <div className="aside-top">
              <div>
                <button
                  onClick={toggleMenu}
                  className="btn btn-icon btn-aside-minimize btn-aside-hide"
                >
                  <i className="text-muted fas fa-stream"></i>
                </button>
              </div>
            </div>

            <nav>
              <ul className="menu-aside">
                <li className="menu-item">
                  <NavLink className="menu-link" to="/admin/">
                    <i className="icon fas fa-home"></i>
                    <span className="text">Dashboard</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink className="menu-link" to="/admin/orders">
                    <i className="icon fas fa-box-alt"></i>
                    <span className="text">Orders</span>
                    {orders && (
                      <span
                        className={`admin-order-notification ${
                          unproc !== "" ? "admin-orders-active" : ""
                        }`}
                      >
                        {unproc}
                      </span>
                    )}
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink className="menu-link" to="/admin/products/">
                    <i className="icon fas fa-edit"></i>
                    <span className="text">View/Edit Products</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink className="menu-link" to="/admin/products/add">
                    <i className="icon fas fa-cart-plus"></i>
                    <span className="text">Add New Product</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink className="menu-link" to="/admin/media">
                    <i className="icon fas fa-image"></i>
                    <span className="text">Media</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink className="menu-link" to="/admin/controls/">
                    <i className="icon fas fa-cogs"></i>
                    <span className="text">Site Settings</span>
                  </NavLink>
                </li>
              </ul>
              <br />
              <br />
            </nav>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default AdminSidebar;
