import React from "react";
import { useRef, useEffect, useState } from "react";

const UploadWidget = ({ onUpload, val }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  const [img, setImg] = useState("");
  useEffect(() => {
    val ? setImg(val) : setImg("");
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dasqsucj5",
        uploadPreset: "unqxktkh",
      },
      function (error, result) {
        if (!error && result && result.event === "success") {
          onUpload(result.info.url);
          setImg(result.info.url);
        }
      }
    );
  }, [val]);
  return (
    <div className="imageUploadContainer">
      <button
        className="imageUploadButton fas fa-arrow-up"
        onClick={(e) => {
          e.preventDefault();
          widgetRef.current.open();
        }}
      ></button>
      <input
        className="imageUploadText"
        type="text"
        placeholder="Upload or Paste URL"
        value={img}
        onChange={(e) => setImg(e.target.value)}
        onBlur={(e) => {
          onUpload(e.target.value);
        }}
      />
    </div>
  );
};

export default UploadWidget;
