import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { contactSettings } from "../../Data/pagecontrols";
import { useDispatch, useSelector } from "react-redux";
import { listControls } from "../../Redux/Actions/ControlActions";
const Footer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listControls());
  }, [dispatch]);
  const [contactControlsFooter, setContactControlsFooter] = useState({
    ...contactSettings,
  });
  const controlList = useSelector((state) => state.controlList);
  const { controls } = controlList;
  useEffect(() => {
    if (controls && controls.length >= 1) {
      const contactControlsTemp = controls[0]
        ? { ...controls[0].contactSettings }
        : { ...contactSettings };
      setContactControlsFooter({ ...contactControlsTemp });
    } else {
      setContactControlsFooter({ ...contactSettings });
    }
  }, [controls]);
  return (
    <div className="ci-footer-container ci-container ci-section">
      <div className="ci-footer-inner-container">
        <div className="ci-footer-socials-container">
          <Link to={contactControlsFooter.facebook}>
            <i className="fab fa-facebook"></i>
          </Link>
          <Link to={contactControlsFooter.instagram}>
            <i className="fab fa-instagram"></i>
          </Link>
          {/* <Link>
            <i className="fab fa-whatsapp"></i>
          </Link> */}
        </div>
        <div className="ci-footer-links-container">
          <Link to="/products" className="ci-footer-link">
            Products
          </Link>
          <Link to="/contact" className="ci-footer-link">
            Contact Us
          </Link>
          <p className="ci-footer-copy-right">
            Copyright Berly Essentials 2023 <i className="fa fa-copyright"></i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
