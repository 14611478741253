import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Navbar from "./../Components/Common/Navbar";
import Gallery from "./../Components/Common/Gallery";
import Footer from "../Components/Common/Footer";
import { listProduct } from "./../Redux/Actions/ProductActions";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";

const ProductsScreen = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    dispatch(listProduct());
  }, []);
  let { filter } = useParams();

  useEffect(() => {
    filter && setSearch(filter);
  }, [filter]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Explore Our Products</title>
        <meta name="description" content="Explore our Finest Products" />
      </Helmet>
      <Navbar />
      <div className="ci-products-screen-container ci-first-section ci-container">
        <div className="ci-products-screen-heading">
          <h2>Products</h2>

          <div className="ci-products-search-container">
            <input
              placeholder="Search here!"
              className="ci-products-search"
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <i className="fas fa-search ci-products-search-icon"></i>
          </div>
        </div>
        <div className="ci-products-screen-gallery">
          <Gallery search={search} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ProductsScreen;
