import React from "react";

const Message = ({ title, message }) => {
  return (
    <div
      style={{
        background: "#e5f7f7",
        borderRadius: "25px",
        padding: "20px 30px",
        width: "100%",
        margin: "0 0 20px",
      }}
    >
      <p
        style={{
          fontSize: "larger",
          fontFamily: "sans-serif",
          color: "dimgray",
          fontWeight: "bold",
        }}
      >
        {title}
      </p>
      <hr style={{ color: "grey" }} />
      <p
        style={{
          fontSize: "medium",
          fontFamily: "sans-serif",
          color: "grey",
        }}
      >
        {message}
      </p>
    </div>
  );
};

export default Message;
